<template>
  
  <div class="relative w-full h-auto bg-white home-a">
    <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
            <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                <div>
                    <div class=""><span class="css-79e8z3">Global Expansion</span></div>
                    <h1
                        class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                        Unlock the doors to Global expansion</h1>
                    <div class=" xl:mt-1">
                        <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                            style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                            Tap into the experience and insights of our exclusive network of talent and service experts to strategically expand global business.</p>
                    </div>
                    <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button @click="$router.push('/client/dashboard').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                            Post Job </button><br>
                        <button @click="$router.push('/freelancer').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                            Find Job </button>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
          <img src="web/b.svg" alt="" class="object-contain h-96 w-100"  />
      </div>
            </div>
        </div>

        <!-- <TheHomeSiteHeader /> -->

        <div class="w-full pt-2  mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div>
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px; ">
                        <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                            Establish Trust
                        </p>
                        Create trust with global teams by timely payments
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            A innovative solution that allows you to search, onboard, pay and provide benefits across borders compliantly and stay connected with your global teams.</p>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/global-expansion/Global-1.jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
                </div>
            </div>
        </div>

       
        <div class="w-full pt-3  mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div class="mobile-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px; ">
                        <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                            International
                        </p>
                        Manage And Pay International Teams With 100% Accuracy
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Pay all your independent contractors from different countries at one go</p>
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Ensure your independent contractors receive their payments in their preferred payment methods that are compliant with local norms</p>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/global-expansion/Global-2.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
                </div> 
                <div class="desktop-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px; ">
                        <p class="text-base uppercase w-400 text-primary-500" style="font-weight: 500;">
                            International
                        </p>
                        Manage And Pay International Teams With 100% Accuracy
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Pay all your independent contractors from different countries at one go</p>
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Ensure your independent contractors receive their payments in their preferred payment methods that are compliant with local norms</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full pt-5  mb-5 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
              
                <div>
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px; ">
                        <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                            Collaboration
                        </p>
                        Project management tools
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                         For the success of your projects, you must establish a healthy and productive process with your independent contractor. By using a Qapin’s project management system, you can manage your contractor’s projects in a transparent and accountable manner. This benefits both parties and ensures that you have logged all the steps of your work, for any future references.
                         <!-- For the success of your projects, you must establish a healthy and productive process with your independent contractor. By using a <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin’s</a> project management system, you can manage your contractor’s projects in a transparent and accountable manner. This benefits both parties and ensures that you have logged all the steps of your work, for any future references. -->
                        </p>
                           
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/global-expansion/Global-3.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
                </div>
            </div>
        </div>

        <!-- ======= Frequently Asked Questions Section ======= -->
        <div id="faq" class="faq section-bg pt-0 pb-5 css-144ctnr" >
            
            <div class="container">

                <div class="section-title">
                    <div class="w-full px-4 mx-auto mt-7 xl:px-0 max-w-7xl">
                        <h2 class="flex justify-center text-3xl text-center text-black fw-bold xl:text-6xl">
                        Frequently asked Questions
                        </h2>
                    </div>
                </div>

                <div class="faq-list">
                    <ul>
                        <li>
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse" class="collapse"
                                data-bs-target="#faq-list-1">Are independent contractors self-employed?<i
                                    class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                <p>
                                    If you hire talents through Qapin, Talents are self-employed. Independent contractors must pay self-employment tax and healthcare. If you’re working with talent through Qapin, you do not need to pay any taxes on independent contractor payments.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="100">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-2" class="collapsed">What taxes does a 1099 employee pay?
                                <i class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    All US independent contractors are 1099 employees need to pay self-employment taxes and income tax. Unlike with W-2 employees, your company doesn’t need to withhold taxes from their paycheck.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="200">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-3" class="collapsed">Do independent contractors get health insurance
                                <i class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Independent contractors are not employees and therefore, companies are not required to provide health insurance. However companies can opt to include this benefit in the contractor agreement.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="300">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-4" class="collapsed">Do independent contractors get a W-2 tax form? <i class="fa fa-chevron-down icon-show"></i><i
                                    class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    A W-2 tax form is for employees. Companies that pay contractors must submit Form 1099 for non-payroll workers. You will Receive one 1099 tax document directly from Qapin each year for the jobs taken up through Qapin
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="400">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-5" class="collapsed">Can an employee also be an independent contractor <i class="fa fa-chevron-down icon-show"></i><i
                                    class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Employees and contractors are two separate statuses and require different tax forms submitted by businesses. However, according to the IRS guidelines, it is possible for an employee to also be an independent contractor if they perform different duties that qualifies them as an independent contractor. While not illegal, it’s not common for a person to be both an independent contractor and an employee.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="500">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-6" class="collapsed">Can an independent contractor end the contract <i class="fa fa-chevron-down icon-show"></i><i
                                    class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-6" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    It’s common for businesses to create contracts with independent contractors that allow them to quit at any time. Both talents and client have option to end the contract at any time. If Talent is ending contract before completion of project, the payments held in escrow will be returned to client. Clients are able to end the contract and As they’re not employees, they may not need to follow commonplace protocols such as providing two weeks or a month’s notice before leaving. Companies will specify any requirements in the contractor agreement.
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
        </div><!-- End Frequently Asked Questions Section -->

        <div class="xl:px-0">
            <div class="inset-0 xl:px-0">
                <div class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto"
                    style="background:  rgb(242 97 60);">
                    <div class="relative footer-vectors">

                    </div>

                    <div class="py-6 text-center xl:py-14">
                        <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>

                        <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                            Register for Qapin today
                        </h4>

                        <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                            style="max-width: 60rem;">
                            it’s the future of inspection services. Bringing the inspection community
                            together in one easy to use platform, Qapin is simple, free and convenient
                            but don’t take our word for it - create your
                        </p>

                        <div class="px-10 mt-6 xl:px-0">
                            <button @click="$router.push('/login').catch(()=>{})"
                                class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                                Join for Free
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
// import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
    },
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped>
.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}

.option-check i {
    margin-right: 15px;
    color: chocolate;
}

.css-79e8z3 {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(13, 32, 73);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 170%;
    background-color: rgb(191 153 153 / 20%);
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.css-12k8mko {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    max-width: 600px;
    display: flex;
    align-items: baseline;
}

.css-1plhbgk {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(28, 28, 28);
    font-family: Silka, Helvetica, sans-serif !important;
    line-height: 170% !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 64px !important;
}

.css-ajm3pi {
    margin: 0px 0px 24px;
    letter-spacing: 0.00735em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 43px;
    font-weight: 700 !important;
    max-width: 600px !important;
    font-size: 28px !important;
}

.hero-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
}

.margin-15 {
    font-size: 19px;
}

.agenct-scale {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    margin-bottom: 16px;
    margin-top: 0;
    text-decoration: none;
}

@media (min-width: 1280px) {
    .xl\:text-6xl {
        font-size: 4.75rem;
        line-height: 1.2;
    }

    .xl\:pt-40 {
        padding-top: 12rem;
    }
    .xl\:max-w-7xl {
    max-width: 85rem;
}
}

.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li+li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #fff !important;
    border: 1px solid #e0dede;
    border-radius: 4px;
    position: relative;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
    color: #f2613c !important;
}

.faq .faq-list .icon-help {
    margin-top: 3px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    left: 20px;
}


.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0 !important;
    padding: 10px 0 0 0;
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    display: flex;
    align-items: baseline;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: #343a40 !important;
}

.faq .faq-list a.collapsed:hover {
    color: #5c9f24;
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5c9f24;
}

.section-title p {
    margin-bottom: 0;
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
