<template>
  <div>
    <!-- New Design Profile -->
    <b-row class="mb-4 agency-new-design mt-68 bg-white">
      <b-col xl="12" lg="12" sm="12">
        <b-card-group deck>
          <b-card class="px-4 py-4 br-0 banner-agency-img" style="background-color : #F2613C !important;">
            <b-container>
              <div class="image-change-icon d-flex align-items-center justify-content-center">
                <a href="javascript:void(0);" class="text-theme" v-b-modal.img-banner-modal>
                  <img src="web/icon-plus.png" class="img-fluid" >
                </a>
                <b-modal id="img-banner-modal" title="Edit Agency Banner" centered hide-footer size="lg" no-close-on-backdrop> 
                  <vue-anka-cropper
                    :options="{
                        aspectRatio: 9.33,
                        closeOnSave: true,
                        cropArea: 'box',
                        croppedHeight: 150,
                        croppedWidth: 1400,
                        cropperHeight: false,
                        dropareaMessage: 'Drop file here or use the button below.',
                        frameLineDash: [5,3],
                        frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                        handleFillColor: 'rgba(255, 255, 255, 0.2)',
                        handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                        handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                        handleSize: 10,
                        handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                        layoutBreakpoint: 850,
                        maxCropperHeight: 768,
                        maxFileSize: 8000000,
                        overlayFill: 'rgba(0, 0, 0, 0.5)',
                        previewOnDrag: true,
                        previewQuality: 0.65,
                        resultQuality: 0.8,
                        resultMimeType: 'image/jpeg',
                        selectButtonLabel: 'Select Files',
                        showPreview: false,
                        skin: 'light',
                        uploadData: {},
                        uploadTo: false}"
                      @cropper-error="someAction(errorMessage)"
                      @cropper-file-selected="someAction(file)"
                      @cropper-preview="someAction(imageSource)"
                      @cropper-saved="UploadFile($event,'agencybanner')"
                      @cropper-cancelled="someAction()"
                      @cropper-uploaded="someAction(serverResponse)">
                    </vue-anka-cropper>
                  <hr />
                  <p class="fz-16">1400X150 Min.</p>
                  <p class="fz-18">Add a banner to the top of the agency profile to showcase your brand, personality, or work. A high-resolution image with minimum 3200px width is recommended. Banners may be adjusted to fit the screen of the viewer.</p>
                  <hr />
                  <div class="text-right btn-group d-flex align-items-center mt-5">
                    <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('img-banner-modal')">Cancel</a>
                    <a title="Save" class="ankaCropper__saveButton btn btn-theme visibility-hidden" v-on:click="uploadProfile()">Save</a>
                  </div>
                </b-modal>
              </div>
              <div class="pos-rel">
                <img :src="profileImg" class="img-fluid pos-absolute proImage" />
                <a href="javascript:void(0);" class="text-theme proImage-add" v-b-modal.img-modal>
                  <img src="web/add_circle.png" class="img-fluid" />
                </a>
                <b-modal id="img-modal" title="Edit photo" centered hide-footer no-close-on-backdrop> 
                  <vue-anka-cropper
                    :options="{
                      aspectRatio: 1,
                      closeOnSave: true,
                      cropArea: 'box',
                      croppedHeight: 400,
                      croppedWidth: 400,
                      cropperHeight: false,
                      dropareaMessage: 'Drop file here or use the button below.',
                      frameLineDash: [5,3],
                      frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                      handleFillColor: 'rgba(255, 255, 255, 0.2)',
                      handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                      handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                      handleSize: 10,
                      handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                      layoutBreakpoint: 850,
                      maxCropperHeight: 768,
                      maxFileSize: 8000000,
                      overlayFill: 'rgba(0, 0, 0, 0.5)',
                      previewOnDrag: true,
                      previewQuality: 0.65,
                      resultQuality: 0.8,
                      resultMimeType: 'image/jpeg',
                      selectButtonLabel: 'Select Files',
                      showPreview: false,
                      skin: 'light',
                      uploadData: {},
                      uploadTo: false}"
                    @cropper-error="someAction(errorMessage)"
                    @cropper-file-selected="someAction(file)"
                    @cropper-preview="someAction(imageSource)"
                    @cropper-saved="UploadFile($event,'agencyprofile')"
                    @cropper-cancelled="someAction()"
                    @cropper-uploaded="someAction(serverResponse)">
                  </vue-anka-cropper>
                  <hr />
                  <div class="text-right btn-group d-flex align-items-center mt-5">
                    <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('img-modal')">Cancel</a>
                    <a title="Save" class="ankaCropper__saveButton btn btn-theme visibility-hidden" v-on:click="uploadProfile()">Save</a>
                  </div>
                </b-modal>
              </div>
            </b-container>
          </b-card>
        </b-card-group>

        <b-container>
          <b-row>
            <b-col xl="9" lg="9" md="9" sm="12" class="mt-5">
              <div class="d-flex align-items-center details-wrapper">
                <h4 class="mb-0 text-black fz-40 fw-600 mr-2 left-part">{{userFullDetails?.company_settings.company_name}}</h4>
                <a href="javascript:void(0);" class="text-theme right-part" @click="setCompanyLine(userFullDetails.company_settings.company_name)" v-b-modal.add-company-name>
                  <img src="web/edit.png" class="img-fluid" />
                </a>
                <!-- this is company model add modal -->
                <b-modal id="add-company-name" centered  no-close-on-backdrop size="lg" title="Company Name" >
                  <form @submit.prevent="updateUserProfile('fullname')" class="text-left">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <div class="form-group">
                        <label>Company Name</label>
                        <b-form-input
                          id="textarea"
                          v-model="companyNameUpdate"
                          placeholder="Company Name.."
                          rows="3"
                          max-rows="3"
                          :error-messages="companyNameError"
                          required
                          @input="$v.companyNameUpdate.$touch()"
                          @blur="$v.companyNameUpdate.$touch()"
                        ></b-form-input>
                        <p class="text-danger" v-if="companyNameError[0]">{{companyNameError[0]}}</p>
                      </div>
                    </b-col>
                  </b-row>
                  </form>
                  <template #modal-footer>
                    <div class="text-right w-100">
                      <a
                        href="javascript:void(0);"
                        class="mr-2 text-theme" @click="$bvModal.hide('add-company-name')"
                        >Cancel</a
                      >
                      <b-button
                        size="lg"
                        class="btn btn-theme"
                        @click="updateUserProfile('fullname')"
                      >
                        Save
                      </b-button>
                    </div>
                  </template>
                </b-modal>
              </div>
              
              <div class="d-flex align-items-center details-wrapper">
                <vue-read-more-smooth :lines="3" class="left-part">
                  <h4 class="mb-0 text-black fz-20 fw-600 mr-2">{{tagLineVal}}</h4>
                </vue-read-more-smooth>
                <a href="javascript:void(0);" class="text-theme right-part" @click="setTagLine()" v-b-modal.add-tagLine>
                  <img src="web/edit.png" class="img-fluid" />
                </a>
                <!-- this is tag line add modal -->
                <b-modal id="add-tagLine" centered  no-close-on-backdrop size="lg" title="Tag Line" >
                  <form @submit.prevent="addTagLine" class="text-left">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <div class="form-group">
                        <label>Tag Line</label>
                        <b-form-input
                          id="textarea"
                          v-model="tagLineself"
                          placeholder="Tag Line.."
                          rows="3"
                          max-rows="3"
                          :error-messages="tagLineselfError"
                          required
                          @input="$v.tagLineself.$touch()"
                          @blur="$v.tagLineself.$touch()"
                        ></b-form-input>
                        <p class="text-danger" v-if="tagLineselfError[0]">{{tagLineselfError[0]}}</p>
                      </div>
                    </b-col>
                  </b-row>
                  </form>
                  <template #modal-footer>
                    <div class="text-right w-100">
                      <a
                        href="javascript:void(0);"
                        class="mr-2 text-theme" @click="$bvModal.hide('add-tagLine')"
                        >Cancel</a
                      >
                      <b-button
                        size="lg"
                        class="btn btn-theme"
                        @click="addTagLine()"
                      >
                        Save
                      </b-button>
                    </div>
                  </template>
                </b-modal>
              </div>
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12">
              <div class="btn-wrapper">
                <b-button class="btn btn-outline-theme w-100 mb-2" v-if="login_master_id != ''" @click="$router.push('/agency-public-profile/'+'ASS'+encodedid(login_master_id)).catch(()=>{})">View Profile as Client</b-button>
                <!-- <b-button class="btn btn-outline-theme w-100 mb-2">Hire Agency</b-button> -->
                <div class="d-flex align-items-center justify-content-center" v-if="userFullDetails.talent_id > 0">
                  <label class="badge mr-2 d-flex text-black align-items-center fz-14 px-0 fw-500 pb-0"> 
                    <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(userFullDetails.talent_id)" />
                    <b>{{filterName(userFullDetails.talent_id)}}</b>
                  </label>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="9" lg="9" md="9" sm="12">
              <div class="overview-wrapper">
                <div class="d-flex align-items-center">
                  <h4 class="mb-2 text-black fz-32 fw-600">Overview</h4>
                  <div class="ml-auto">
                    <a href="javascript:void(0);" class="text-theme" @click="setAbout()" v-b-modal.add-about>
                      <img src="web/edit.png" class="img-fluid" />
                    </a>
                    
                  </div>
                </div>
                <hr />
                <template v-if="aboutYourselfVal == '' || aboutYourselfVal == null">
                  <p class="fz-16 fw-600 text-black">
                    No Details Available
                  </p>
                </template>
                <template v-else>
                  <vue-read-more-smooth :lines="6">
                    <p class="fz-16 fw-600 text-black line-break-normal" v-html="getHtmlContent(aboutYourselfVal)"></p>
                  </vue-read-more-smooth>
                  <b-modal id="add-about" centered no-close-on-backdrop size="lg" title="Overview" >
                    <div>
                      <p class="mb-md-20">Use this space to show clients you have the skills and experience they're looking for.</p>
                      <ul class="list-style-disc">
                        <li>Describe your strengths and skills</li> 
                        <li>Highlight projects, accomplishments and education</li> 
                        <li>Keep it short and make sure it's error-free</li>
                      </ul> 
                    </div>
                    <form action="" class="text-left">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <div class="form-group">
                            <label>Describe Yourself</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="aboutYourself"
                              placeholder="Description here.."
                              rows="3"
                              max-rows="6"
                              :error-messages="aboutYourselfError"
                              required
                              @input="$v.aboutYourself.$touch()"
                              @blur="$v.aboutYourself.$touch()"
                            ></b-form-textarea>
                            <p class="text-danger" v-if="aboutYourselfError[0]">{{aboutYourselfError[0]}}</p>
                          </div>
                        </b-col>
                      </b-row>
                    </form>
                    <template #modal-footer>
                      <div class="text-right w-100">
                        <a
                          href="javascript:void(0);"
                          class="mr-2 text-theme" @click="$bvModal.hide('add-about')"
                          >Cancel</a
                        >
                        <b-button
                          size="lg"
                          class="btn btn-theme"
                          @click="addAbout()"
                        >
                          Save
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </template>
              </div>

              <AgencyService pageType='profile' userId='null' />

              <div class="skills-wrapper mt-4">
                <div class="d-flex align-items-center">
                  <h4 class="mb-2 text-black fz-32 fw-600">Skills</h4>
                  <div class="ml-auto">
                    <a href="javascript:void(0);" class="text-theme" v-b-modal.modal-addSkills-new @click="getSkills()">
                      <img src="web/edit.png" class="img-fluid">
                    </a>
                    <b-modal id="modal-addSkills-new" centered hide-footer no-close-on-backdrop :title="(addskills)?'Edit Skills':'Add Skills'" size="md">
                      <template>
                        <div>
                          <form @submit.prevent="addSkillData" class="text-left">
                            <b-form-group id="Agency-size" label="" label-for="Agency-size">
                              <multiselect 
                                v-model="addskills"
                                placeholder="Search & add SKills"
                                label="skill_name"
                                track-by="skill_name"                                                
                                :options="skillsData"
                                :multiple="true"
                                :taggable="true"
                              ></multiselect>
                            </b-form-group>
                            <p class="text-danger" v-if="agencySizeErrors[0]">{{agencySizeErrors[0]}}</p>
                            <hr>
                            <div class="text-right">
                              <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('modal-addSkills-new')">Cancel</a>
                              <b-button type="submit"  class="btn btn-theme">Save</b-button>
                            </div>
                          </form>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                </div>
                <hr>
                <div class="skills-container d-flex flex-wrap" v-if="companySettingData.company_skills">
                  <b-badge
                    v-for="(catName, cids) in companySettingData.company_skills"
                    :key="cids"
                    variant="primary"
                    class="skill-badge mx-1 my-1"
                  >
                    {{ catName.skill_name }}
                    <a href="javascript:" class="text-muted">
                        <div class="up-icon xs pt-1">
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1" v-on:click="removefromCategoryarr(cids)"><rect width="0.878423" height="8.78423" rx="0.439212" transform="matrix(0.703924 0.710276 -0.703924 0.710276 9.22266 3.08838)" fill="#F2613C"></rect><rect width="0.878423" height="8.78423" rx="0.439212" transform="matrix(0.703929 -0.71027 0.703929 0.71027 3.03906 3.7124)" fill="#F2613C"></rect></svg>
                        </div>
                    </a>
                  </b-badge>
                </div>
              </div>

              <div class="portfolio-wrapper mt-4">
                <AgencyProjectNew pageType="profile" pageName='agency-profile' />
              </div>

              <div class="business-manager-wrapper mt-4" v-if ="agencyManager.length > 0">
                <h4 class="mb-2 text-black fz-32 fw-600">Business Managers</h4>
                <hr>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" v-if="agencyManager.length > 0 ">
                    <div class="bg-grey p-4 br-20">
                      <template v-for="(manager,index) in agencyManager">
                        <div class="list-managers mb-4" :key="index" v-if="index < 3 && !allAgencyManager">
                          <div class="d-flex align-items-center mb-1">
                            <div class="img-wrapper mr-2">
                              <img :src="manager.profile_picture_path" class="img-fluid w-100">
                            </div>
                            <div>
                              <div class="name-content mr-4 d-md-flex align-items-center">
                                <h5 class="fz-18 fw-600 text-black mr-2 cursor-pointer mb-0" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{shortName(manager.first_name,manager.last_name)}}</h5>
                                <label class="badge mr-2 d-flex text-black align-items-center text-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                  <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                  <b>{{filterName(manager.talent_id)}}</b>
                                </label>
                              </div>
                              <div class="percent-content d-flex align-items-center">
                                <span class="mr-5">Job Success</span>
                                <h5 class="fz-18 fw-600 text-black pt-1 mb-0">{{manager.jobSuccessRate}}%</h5>
                              </div>
                            </div>
                          </div>
                          <!-- <b-button class="btn btn-outline-theme mb-4">Invite to Job</b-button> -->
                        </div>
                        <div class="list-managers mb-4" :key="index" v-else-if="allAgencyManager">
                          <div class="d-flex align-items-center mb-1">
                            <div class="img-wrapper mr-2">
                              <img :src="manager.profile_picture_path" class="img-fluid w-100">
                            </div>
                            <div>
                              <div class="name-content mr-4 d-md-flex align-items-center">
                                <h5 class="fz-18 fw-600 text-black mr-2 cursor-pointer mb-0" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{shortName(manager.first_name,manager.last_name)}}</h5>
                                <label class="badge mr-2 d-flex text-black align-items-center text-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                  <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                  <b>{{filterName(manager.talent_id)}}</b>
                                </label>
                              </div>
                              <div class="percent-content d-flex align-items-center">
                                <span class="mr-5">Job Success</span>
                                <h5 class="fz-18 fw-600 text-black pt-1 mb-0">{{manager.jobSuccessRate}}%</h5>
                              </div>
                            </div>
                          </div>
                          <!-- <b-button class="btn btn-outline-theme mb-4">Invite to Job</b-button> -->
                        </div>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" class="text-center">
                    <b-button class="btn btn-outline-theme" v-if="agencyManager.length > 3  && allAgencyManager == false" @click="allAgencyManager = true">View More Business Managers</b-button>
                    <b-button class="btn btn-outline-theme" v-else-if="allAgencyManager" @click="allAgencyManager = false">View Less Business Managers</b-button>
                  </b-col>
                </b-row>
              </div>

              <div class="agency-manager-wrapper mt-4" v-if="agencyMember.length > 0 ">
                <h4 class="mb-2 text-black fz-32 fw-600">Agency Members</h4>
                <hr>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" v-if="agencyMember.length > 0 ">
                    <div class="bg-grey p-4 br-20">
                      <template v-for="(manager,index) in agencyMember" class="d-flex">
                        <div class="list-managers mb-4" :key="index" v-if="index < 3 && !allAgencyMember">
                          <div class="d-flex align-items-center">
                            <div class="img-wrapper mr-2">
                              <img :src="manager.profile_picture_path" class="img-fluid w-100">
                            </div>
                            <div>
                              <div class="name-content mr-4 d-md-flex align-items-center">
                                <h5 class="fz-18 fw-600 text-black mr-2 cursor-pointer mb-0" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{shortName(manager.first_name,manager.last_name)}}</h5>
                                <label class="badge mr-2 d-flex text-black align-items-center text-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                  <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                  <b>{{filterName(manager.talent_id)}}</b>
                                </label>
                              </div>
                              <div class="percent-content d-flex align-items-center">
                                <span class="mr-5">Job Success</span>
                                <h5 class="fz-18 fw-600 text-black pt-1 mb-0" >{{manager.jobSuccessRate}}%</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="list-managers mb-4" :key="index" v-else-if="allAgencyMember">
                          <div class="d-flex align-items-center">
                            <div class="img-wrapper mr-2">
                              <img :src="manager.profile_picture_path" class="img-fluid w-100">
                            </div>
                            <div>
                              <div class="name-content mr-4 d-md-flex align-items-center">
                                <h5 class="fz-18 fw-600 text-black mr-2 cursor-pointer mb-0" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{shortName(manager.first_name,manager.last_name)}}</h5>
                                <label class="badge mr-2 d-flex text-black align-items-center text-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                  <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                  <b>{{filterName(manager.talent_id)}}</b>
                                </label>
                              </div>
                              <div class="percent-content d-flex align-items-center">
                                <span class="mr-5">Job Success</span>
                                <h5 class="fz-18 fw-600 text-black pt-1 mb-0" >{{manager.jobSuccessRate}}%</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" class="text-center">
                    <b-button class="btn btn-outline-theme" v-if="agencyMember.length > 3 && allAgencyMember == false" @click="allAgencyMember = true">View More Agency Managers</b-button>
                    <b-button class="btn btn-outline-theme" v-else-if="allAgencyMember" @click="allAgencyMember = false">View less Agency Managers</b-button>
                  </b-col>
                </b-row>
              </div>

              <div class="whistory-wrapper">
                <h4 class="mb-2 text-black fz-32 fw-600">Work History</h4>
                <hr>
                <b-tabs>
                  <!-- Completed Jobs Tab -->
                  <b-tab :title="'Completed Jobs ('+getCompletedJobList.length+')'" active>
                    <template v-if="getCompletedJobList.length > 0">
                      <b-row >
                        <b-col xl="12" lg="12" md="12" sm="12">
                          <template v-for="(contract ,ind) in  getCompletedJobList">
                            <div class="inner-content border-bottom" :key="ind" v-if="ind+1 <= competedJobView">
                              <h5 class="fz-22 fw-600 text-black" @click="$router.push({name: $store.state.accountType+'contractDetail',path:'/'+this.$store.state.accountType+'/contract-detail/',params:{pid:contract.uniq_id,uid:'A'+encodedid(contract.freelancer_id)}}).catch(()=>{})">{{contract.job_title}}</h5>
                              <div class="d-md-flex align-items-center">
                                <ul class="d-flex align-items-center minus__32 mb-2">
                                  <li class="d-flex align-items-center mr-2">
                                    <b-form-rating
                                      v-model="contract.to_rating"
                                      stars="5"
                                      icon-empty="star"
                                      icon-filled="star-fill"
                                      icon-half="star-half"
                                      variant="warning"
                                      :disabled="true"
                                    ></b-form-rating>
                                  </li>
                                  <li class="d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#666666" class="bi bi-clock-fill mr-2" viewBox="0 0 16 16">
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                    </svg>
                                    <span class="fz-16 fw-500 text-muted">{{contract.contractStart}}  {{contract.contractEnd}}</span>
                                  </li>
                                </ul>
                                <div class="ml-auto">
                                  <span class="fz-18 fw-600 text-black">{{'$'+contract.burget}}</span>
                                </div>
                              </div>
                              <template v-if="contract.to_review != '' && contract.to_review != null">
                                <vue-read-more-smooth :lines="4" >
                                  <p class="fz-18 fw-400 text-black" v-html="getHtmlContent(contract.to_review)"></p>
                                </vue-read-more-smooth>
                              </template>
                              <template v-else>
                                <p class="fz-18 fw-400 text-black">No feedback given.</p>
                              </template>
                              <!-- <p class="fz-18 fw-400 text-black">"Infotech delivered good work on this project and I enjoyed working with them and will likely have additional jobs for him in the future."</p> -->
                            </div>
                          </template>
                        </b-col>
                        <b-col xl="12" lg="12" md="12" sm="12" class="text-center" v-if="getCompletedJobList.length > competedJobView">
                          <b-button class="btn btn-outline-theme" @click="competedJobView += 3">View More Work History</b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <div class="whistory-wrapper" v-else>
                      <div class="pro-img-wrapper">
                        <img
                          :src="$store.state.baseUrlImg+'/freelancer/work-history.png'"
                          class="m-auto img-fluid"
                        />
                      </div>
                      <div class="pro-content text-center">
                        <h4 class="mt-2 mb-0 text-black fz-24 fw-600">
                          Completed Jobs
                        </h4>
                        <p class="fz-16 fw-600 text-muted">
                          Not Available
                        </p>
                      </div>
                    </div>
                  </b-tab>

                  <!-- In-Progress Tab -->
                  <b-tab :title="'In-Progress ('+getPendingJobList.length+')'">
                    <template v-if="getPendingJobList.length > 0">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12">
                          <template v-for="(contract ,ind) in  getPendingJobList">
                            <div class="inner-content border-bottom" :key="ind" v-if="ind+1 <= pendingJobView">
                              <h5 class="fz-22 fw-600 text-black">{{contract.job_title}}</h5>
                            </div>
                          </template>
                        </b-col>
                        <b-col xl="12" lg="12" md="12" sm="12" class="text-center" v-if="getPendingJobList.length > pendingJobView">
                          <b-button class="btn btn-outline-theme"  @click="pendingJobView += 3">View More Work History</b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <div class="whistory-wrapper" v-else>
                      <div class="pro-img-wrapper">
                        <img
                          :src="$store.state.baseUrlImg+'/freelancer/work-history.png'"
                          class="m-auto img-fluid"
                        />
                      </div>
                      <div class="pro-content text-center">
                        <h4 class="mt-2 mb-0 text-black fz-24 fw-600">
                          In-progress Jobs
                        </h4>
                        <p class="fz-16 fw-600 text-muted">
                          Not Available
                        </p>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12">
              <div class="activity-wrapper mt-4 pt-1">
                <hr>
                <h4 class="mb-2 text-black fz-32 fw-600">Qapin Activity</h4>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <span class="fz-16 fw-600 text-muted">Job Success</span>
                    <h5 class="fz-24 fw-600 text-black mb-0">{{userFullDetails.jobSuccessRate}}%</h5>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <span class="fz-16 fw-600 text-muted">Total Jobs</span>
                    <h5 class="fz-24 fw-600 text-black mb-0">{{getCompletedJobList.length}}</h5>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <span class="fz-16 fw-600 text-muted">Total Earnings</span>
                    <h5 class="fz-24 fw-600 text-black mb-0">${{userFullDetails.totalEarn}}</h5>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <AgencyPriceRange pageType = "profile" :companyData='userFullDetails.company_settings' @getLatestData="getUserDetails()"></AgencyPriceRange>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <div class="d-flex align-items-center">
                      <div class="left-part">
                        <span class="fz-16 fw-600 text-muted">Company Size</span>
                        <h5 class="fz-24 fw-600 text-black mb-0" v-if="companySettingData.company_size" >{{companySettingData.company_size}} Workers</h5>
                      </div>
                      <div class="ml-auto d-flex align-items-center right-part">
                        <a href="javascript:void(0);" class="text-theme" v-b-modal.modal-editcompanySize @click="getCompanysize(); companySizevalue = companySettingData.company_size;" >
                          <img src="web/edit.png" class="img-fluid">
                        </a>
                        <b-modal id="modal-editcompanySize" centered hide-footer no-close-on-backdrop title="Edit Agency Size" size="md">
                          <template>
                            <div>
                              <form @submit.prevent="addupdateagencySize" class="text-left">
                                <b-form-group id="Agency-size" label="Agency-size" label-for="Agency-size">
                                  <b-form-select
                                    id="Agency-size"
                                    class="form-control mb-3"
                                    :options="companysize"
                                    value-field="title" 
                                    text-field="title"
                                    value="Select an option" 
                                    v-model="companySizevalue"
                                    @input="$v.companySizevalue.$touch()"
                                    @blur="$v.companySizevalue.$touch()"
                                  ></b-form-select>
                                </b-form-group>
                                <p class="text-danger" v-if="agencySizeErrors[0]">{{agencySizeErrors[0]}}</p>
                                <hr>
                                <div class="text-right">
                                  <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('modal-editcompanySize')">Cancel</a>
                                  <b-button type="submit"  class="btn btn-theme">Save</b-button>
                                </div>
                              </form>
                            </div>
                          </template>
                        </b-modal>
                      </div>
                    </div>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <div class="d-flex align-items-center">
                      <div class="left-part">
                        <span class="fz-16 fw-600 text-muted">Year Founded</span>
                        <h5 class="fz-24 fw-600 text-black mb-0" v-if="companySettingData.company_founded">{{companySettingData.company_founded}}</h5>
                      </div>
                      <div class="ml-auto d-flex align-items-center right-part">
                        <a href="javascript:void(0);" class="text-theme mr-2" v-b-modal.modal-editFounded @click="companyfounded = companySettingData.company_founded">
                          <img src="web/edit.png" class="img-fluid">
                        </a>
                        <!-- <a href="javascript:void(0);" class="text-theme">
                          <img src="web/delete.png" class="img-fluid">
                        </a> -->
                        <b-modal id="modal-editFounded"  centered hide-footer no-close-on-backdrop  title="Add Year founded" size="md">
                          <template>
                            <div>
                              <form @submit.prevent="addupdateFounded" class="text-left">
                                <b-form-group id="founded" label="What year was the agency founded?" label-for="founded">
                                  <b-form-input
                                    id="founded"
                                    class="form-control mb-3"
                                    v-model="companyfounded"
                                    minlength="4"
                                    maxlength="4"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                                    @input="$v.companyfounded.$touch()"
                                    @blur="$v.companyfounded.$touch()"
                                    :error-messages="foundedErrors"
                                    aria-placeholder="Enter Year of founded"
                                  ></b-form-input>
                                  <p style="color: #b7a7a7;margin-bottom: 0px!important;">Hint:- {{new Date().getFullYear()}}</p>
                                </b-form-group>
                                <p class="text-danger" v-if="foundedErrors[0]">{{foundedErrors[0]}}</p>
                                <hr>
                                <div class="text-right">
                                  <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('modal-editFounded')" >Cancel</a>
                                  <b-button  type="submit" class="btn btn-theme">Save</b-button>
                                </div>
                              </form>
                            </div>
                          </template>
                        </b-modal>
                      </div>
                    </div>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <span class="fz-16 fw-600 text-muted">Qapin Member since</span>
                    <h5 class="fz-24 fw-600 text-black mb-0">{{convertdateMDY(userFullDetails.created_at)}}</h5>
                  </b-col>
                </b-row>
              </div>

              <div class="language-wrapper mt-4 pt-1">
                <hr>
                <div class="d-flex align-items-center">
                  <div>
                    <h4 class="mb-2 text-black fz-32 fw-600">Languages</h4>
                  </div>
                  <div class="ml-auto d-flex align-items-center">
                    <a href="javascript:void(0);" class="text-theme mr-2" v-b-modal.modal-editlanguage @click="setLanguage()">
                      <img src="web/edit.png" class="img-fluid">
                      <!-- <img src="web/add_circle.png" class="img-fluid"> -->
                    </a>
                  </div>
                </div>
                <b-row v-if="userFullDetails.language_arr.length > 0">
                  <b-col xl="12" lg="12" md="12" sm="12" v-if="userFullDetails.language_arr.length > 0">
                    <div class="d-flex align-items-center" v-for="(lang,index) in userFullDetails.language_arr" :key="index">
                      <div>
                        <h5 class="fz-20 fw-600 text-black mb-2">{{lang}} : <span class="fz-20 fw-600 text-muted">{{_proficiency(userFullDetails.proficiency_id[index])}}</span></h5>
                      </div>
                      <!-- <div class="ml-auto d-flex align-items-center">
                        <a href="javascript:void(0);" class="text-theme mr-2">
                          <img src="web/edit.png" class="img-fluid">
                        </a>
                        <a href="javascript:void(0);" class="text-theme">
                          <img src="web/delete.png" class="img-fluid">
                        </a>
                      </div> -->
                    </div>
                    <b-modal id="modal-editlanguage"  title="Add Languages" centered hide-footer no-close-on-backdrop size="lg" >
                      <template>
                        <div>
                          <b-form @submit.prevent="updateUserProfile('language')">
                            <b-row v-for="(v,index) in proficiencyVal" :key="index">
                              <b-col xl="5" lg="5" sm="12" :class="v">
                                <b-form-group id="language" label="Language" label-for="language" >
                                  <b-form-select
                                    id="language"
                                    class="form-control mb-3"
                                    :options="languageData"
                                    text-field="value"
                                    value-field="id"
                                    required
                                    v-model="languageVal[index]"
                                    v-if="index == 0"
                                    disabled=""
                                  ></b-form-select>
                                  <b-form-select
                                    id="language"
                                    class="form-control mb-3"
                                    :options="languageData"
                                    text-field="value"
                                    value-field="id"
                                    required
                                    v-model="languageVal[index]"
                                    v-else
                                  ></b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col xl="5" lg="5" sm="12">
                                <b-form-group id="proficiency" label="Proficiency Level" label-for="proficiency level">
                                  <b-form-select
                                    id="proficiency"
                                    class="form-control mb-3"
                                    :options="proficiency"
                                    required
                                    v-model="proficiencyVal[index]"
                                    v-if="index == 0"
                                  ></b-form-select>
                                  <b-form-select
                                    id="proficiency"
                                    class="form-control mb-3"
                                    :options="proficiency"
                                    required
                                    v-model="proficiencyVal[index]"
                                    v-else
                                  ></b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col xl="2" lg="2" sm="12" v-if="index == 0">
                                <button type="button" class="btn btn-theme mt-4" @click="addLanguage()"><i class="fa fa-plus"></i></button>
                              </b-col>
                              <b-col xl="2" lg="2" sm="12" v-else>
                                <button type="button" class="btn btn-danger mt-4" @click="removeLanguage(index)"><i class="fa fa-close"></i></button>
                              </b-col>
                            </b-row>
                            <hr>
                            <b-col xl="12" lg="12" sm="12">
                              <div class="text-right">
                                <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('modal-editlanguage')">Cancel</a>
                                <b-button  type="submit" class="btn btn-theme">Save</b-button>
                              </div>
                            </b-col>
                          </b-form>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                </b-row>
              </div>

              <div class="location-wrapper mt-4 pt-1">
                <hr>
                <div class="d-flex align-items-center">
                  <div>
                    <h4 class="mb-2 text-black fz-32 fw-600">Office Location</h4>
                  </div>
                  <div class="ml-auto d-flex align-items-center">
                    <a href="javascript:void(0);" class="text-theme" v-b-modal.edit-location-modal @click="addLocationModel();locationModelTitle = 'Add Location'">
                      <img src="web/add_circle.png" class="img-fluid">
                    </a>
                    <b-modal  centered no-close-on-backdrop hide-footer id="edit-location-modal" size="lg" :title="locationModelTitle" >
                      <div class="text-left">
                        <p class="fz-14 fw-500 text-black">The primary location is linked to your agency's Qapin account, and is used for administrative and financial purposes . Only the city and country is displayed on your agency profile.</p>
                        <form @submit.prevent="addupdateLocation()">
                          <b-form-group class="mb-4 pos-rel">
                            <label>Country</label>
                            <b-form-select 
                              v-model="addlocation.country_id"
                              :options="CountryData"
                              value-field="country_id" 
                              text-field="country_name"
                              @change="changecountry($event)"
                              @input="$v.addlocation.country_id.$touch()"
                              @blur="$v.addlocation.country_id.$touch()"
                              class="form-control">
                            </b-form-select>
                            <p class="text-danger" v-if="locationCountryErrors[0]">{{locationCountryErrors[0]}}</p>
                          </b-form-group>
                          <b-form-group class="mb-4 pos-rel">
                            <label>State</label>
                            <b-form-select
                            v-model="addlocation.state_id"
                            :options="stateData"
                            value-field="state_id" 
                            text-field="state_name"
                            @change="changestate($event)"
                            @input="$v.addlocation.state_id.$touch()"
                            @blur="$v.addlocation.state_id.$touch()"
                            class="form-control"></b-form-select>
                            <p class="text-danger" v-if="locationStateErrors[0]">{{locationStateErrors[0]}}</p>
                          </b-form-group>
                          <b-form-group class="mb-4 pos-rel">
                            <label>City</label>
                            <b-form-select
                            v-model="addlocation.city_id"
                            :options="cityData"
                            value-field="city_id" 
                            text-field="city_name"
                            @input="$v.addlocation.city_id.$touch()"
                            @blur="$v.addlocation.city_id.$touch()"
                            class="form-control"></b-form-select>
                            <p class="text-danger" v-if="locationCityErrors[0]">{{locationCityErrors[0]}}</p>
                          </b-form-group>
                          <b-form-group class="mb-4 pos-rel">
                            <label>Postal Code</label>
                            <b-form-input
                            maxlength="10" 
                            v-model="addlocation.zip_code"
                            @input="$v.addlocation.zip_code.$touch()"
                            @blur="$v.addlocation.zip_code.$touch()"
                            placeholder="Enter postal code"></b-form-input>
                            <p class="text-danger" v-if="locationZipcodeErrors[0]">{{locationZipcodeErrors[0]}}</p>

                          </b-form-group>
                          <b-form-group class="mb-4 pos-rel">
                            <label>Address</label>
                            <b-form-textarea id="textarea"
                            v-model="addlocation.address"
                            @input="$v.addlocation.address.$touch()"
                            @blur="$v.addlocation.address.$touch()"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"></b-form-textarea>
                            <p class="text-danger" v-if="locationAddressErrors[0]">{{locationAddressErrors[0]}}</p>

                          </b-form-group>
                          <b-form-group v-if="hideLocationpriOption">
                            <b-form-checkbox v-model="addlocation.is_head_office" value="1" class="d-flex align-items-center text-muted">Make Address as Primay </b-form-checkbox>
                          </b-form-group>
                          <p class="text-danger" v-if="agencySizeErrors[0]">{{agencySizeErrors[0]}}</p>
                          <hr>
                          <div class="w-100 text-right">
                            <a href="javascript:void(0);" class="text-theme mr-2" @click=" hideLocationpriOption = true; $bvModal.hide('edit-location-modal')">Cancel</a>
                            <b-button
                              type="submit"
                              size="lg"
                              class="btn btn-theme">
                              Save
                            </b-button>
                          </div>
                        </form>
                      </div>
                    </b-modal>
                  </div>
                </div>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <div class="d-flex align-items-normal mb-2" v-if="locationData.branch_primary!= undefined && locationData.branch_primary!=''">
                      <div class="left-part d-flex align-items-normal">
                        <div class="img-wrapper mr-2">
                          <img src="web/pin.png" class="img-fluid">
                        </div>
                        <div class="address">
                          <h5 class="fz-20 fw-600 mb-0">{{locationData.branch_primary.city_name}}, {{(locationData.branch_primary.country_name!='')?locationData.branch_primary.country_name:''}}</h5>
                          <span class="fz-14 text-muted fw-400">6:07PM GMT+5:30 Primary location</span>
                        </div>
                      </div>
                      <div class="ml-auto d-flex align-items-center right-part">
                        <a href="javascript:void(0);" class="text-theme" @click="editLocationmodel(locationData.branch_primary); locationModelTitle = 'Edit Location'">
                          <img src="web/edit.png" class="img-fluid">
                        </a>
                      </div>
                    </div>
                    <template v-if="locationData.head != undefined && locationData.head.length > 0">
                      <div class="d-flex align-items-normal" v-for="(loc,index) in locationData.head" :key="index">
                        <div class="left-part d-flex align-items-normal">
                          <div class="img-wrapper mr-2">
                            <img src="web/pin.png" class="img-fluid">
                          </div>
                          <div class="address">
                            <h5 class="fz-20 fw-600 mb-0">{{loc.city_name}}, {{(loc.country_name!='')?loc.country_name:''}}</h5>
                            <span class="fz-14 text-muted fw-400">5:07PM GMT+5:30</span>
                          </div>
                        </div>
                        <div class="ml-auto d-flex align-items-center right-part">
                          <a href="javascript:void(0);" class="text-theme mr-2" @click="editLocationmodel(loc); locationModelTitle = 'Edit Location'">
                            <img src="web/edit.png" class="img-fluid">
                          </a>
                          <a href="javascript:void(0);" class="text-theme" v-b-modal.confirm-modal @click="removeLocationlId = loc.company_location_id">
                            <img src="web/delete.png" class="img-fluid">
                          </a>
                          <!-- model for confirm reject -->
                          <b-modal id="confirm-modal" title="Remove Location" centered hide-footer size="md" no-close-on-backdrop> 
                              <template>
                              <div class="p-5 text-center m-auto">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                  </svg>
                                  <h5 class="mt-4">Are you sure, Want to Remove this Location?</h5>
                              </div>
                              <hr>
                              <div>
                                  <div class="text-right">
                                      <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('confirm-modal')">Cancel</a>
                                      <b-button type="button" class="btn btn-theme" @click="removeLocation(removeLocationlId)">Confirm</b-button>
                                  </div>
                              </div>
                            </template>
                          </b-modal>
                        </div>
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { validationMixin } from 'vuelidate';
import { required,maxValue,maxLength,minLength } from 'vuelidate/lib/validators';
import VueReadMoreSmooth from "vue-read-more-smooth";
import vueAnkaCropper from 'vue-anka-cropper'
import $ from 'jquery'; 
import { mapActions, mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import AgencyPriceRange from "../../components/profile/agencyPriceRange.vue"; 
import AgencyProjectNew from "../../components/profile/agency-project-new.vue"; 
import AgencyService from "../../components/profile/agencyService.vue";
import axios from 'axios';
export default {
  name: "JobTabs",
  mixins: [validationMixin],
  validations: { 
    companyNameUpdate: { 
      required,
      maxLength: maxLength(50),
      minLength: minLength(10) 
    },
    aboutYourself: { 
      required,
      maxLength: maxLength(2000),
      minLength: minLength(200) 
    },
    tagLineself: { 
      required,
      maxLength: maxLength(150),
      minLength: minLength(20) 
    },
    
    addlocation:{
      country_id:{ required },
      state_id:{ required },
      city_id:{ required },
      address:{ required },
      zip_code:{ required },
    },

    companyfounded:{ 
      required,
      minLength: minLength(4),
      maxLength: maxLength(4), 
      maxValue: maxValue(new Date().getFullYear()),
    },
    companySizevalue:{ required },
    company_name:{ required },
  },
  components: { 
     VueReadMoreSmooth,
     vueAnkaCropper,
     Multiselect,
     AgencyPriceRange,
     AgencyProjectNew,
     AgencyService,
  },
  data() {
    return { 
      skills: ["HTML", "CSS", "JavaScript", "Vue.js", "Bootstrap", "Node.js"],
      editName : false,
      company_name : '',
      timeClock:'',
      login_master_id:'',
      //new for test
      file:'',
      errorMessage:'',
      imageSource:'',
      cropData:'',
      serverResponse:'',
      //test
      is_primary:false,
      yearOfExperience:'',
      availabilityVal:'',
      languageVal:[],
      proficiencyVal:[],
      nationalityVal:'',
      tagLineVal:'',
      tagLineself:'',
      companyNameUpdate:'',
      profileImg:'',
      bannerImg:'',
      rating: 0,
      userFullDetails:{
        language_arr:{},
        company_settings:{
          company_name:'',
        }
      },
      icons: {
        1: false, // Accordion 1 closed initially
        2: false, // Accordion 2 closed initially
        3: false  // Accordion 3 closed initially
      },
      categoryData:[],
      getCompletedJobList:[],
      getPendingJobList:[],
      availabilityArr:[
        { value: null, text: 'Select availability' },
        { value: '1', text: 'Adhoc - Less than 30 hrs/week' },
        { value: '2', text: 'Residence - More than 40 hrs/week' },
      ],
      isAdd: false,
      aboutYourself: '',
      aboutYourselfVal:'',
      // languages: [
      //   { value: null, text: 'Select Language' },
      //   { value: '1', text: 'English' },
      //   { value: '2', text: 'Chinese' },
      // ],
      proficiency: [
        { value: null, text: 'Select Proficiency Level' },
        { value: '1', text: 'Basic' },
        { value: '2', text: 'Conversational' }, 
        { value: '3', text: 'Fluent' }, 
        { value: '4', text: 'Native or Bilingual' },
      ],
      nationalityArray:[],
      languageData:[],
      companysize: [],
      companySettingData:[],
      companySizevalue:'',
      companyfounded:'',
      locationModelTitle:'',
      CountryData:[],
      stateData:[],
      cityData:[],
      addlocation:{
        country_id:'',
        state_id:'',
        city_id:'',
        address:'',
        zip_code:'',
        is_head_office :'',
        company_location_id:''
      },
      locationData:[],
      removeLocationlId:'',
      hideLocationpriOption:true,
      skillsData:[],
      addskills:[],
      associatedFreelancerData:[],
      agencyManager:[],
      allAgencyManager:false,
      agencyMember:[],
      allAgencyMember:false,
      talentQualilty:[],
      competedJobView : 3,
      pendingJobView : 3,
    }
  },
  computed:{
    ...mapGetters(["getTalentListdata"]),
    companyNameError(){
      const errors = []
      if (!this.$v.companyNameUpdate.$dirty) return errors 
      !this.$v.companyNameUpdate.required && errors.push('Tag line is required')
      !this.$v.companyNameUpdate.maxLength && errors.push('Too long. Use at least 50 characters or less')
      !this.$v.companyNameUpdate.minLength && errors.push('Tag line must be minimum 10 characters')
      return errors
    },
    tagLineselfError(){
      const errors = []
      if (!this.$v.tagLineself.$dirty) return errors 
      !this.$v.tagLineself.required && errors.push('Tag line is required')
      !this.$v.tagLineself.maxLength && errors.push('Too long. Use at least 150 characters or less')
      !this.$v.tagLineself.minLength && errors.push('Tag line must be minimum 20 characters')
      return errors
    },
    aboutYourselfError() {
        const errors = []
        if (!this.$v.aboutYourself.$dirty) return errors 
        !this.$v.aboutYourself.required && errors.push('Overview is required')
        !this.$v.aboutYourself.maxLength && errors.push('Too long. Use at least 2000 characters or less')
        !this.$v.aboutYourself.minLength && errors.push('Overview must be minimum 200 characters')
        return errors
    },
     
    foundedErrors(){
      const errors = []
      if (!this.$v.companyfounded.$dirty) return errors 
      !this.$v.companyfounded.required && errors.push('Company founded is required')
      !this.$v.companyfounded.maxLength && errors.push('Too long. Use at least 4 characters')
      !this.$v.companyfounded.minLength && errors.push('Too short. Use at least 4 characters')
      !this.$v.companyfounded.maxValue && errors.push('Not Allow Greater Year than current')
        return errors
    },
    agencySizeErrors(){
      const errors = []
      if (!this.$v.companySizevalue.$dirty) return errors 
      !this.$v.companySizevalue.required && errors.push('Agency Size is required')
        return errors
    },

    locationCountryErrors(){
      const errors = []
      if (!this.$v.addlocation.country_id.$dirty) return errors 
      !this.$v.addlocation.country_id.required && errors.push('Country is required')
        return errors
    },

    locationStateErrors(){
      const errors = []
      if (!this.$v.addlocation.state_id.$dirty) return errors 
      !this.$v.addlocation.state_id.required && errors.push('State is required')
        return errors
    },

    locationCityErrors(){
      const errors = []
      if (!this.$v.addlocation.city_id.$dirty) return errors 
      !this.$v.addlocation.city_id.required && errors.push('City is required')
        return errors
    },

    locationAddressErrors(){
      const errors = []
      if (!this.$v.addlocation.address.$dirty) return errors 
      !this.$v.addlocation.address.required && errors.push('Address is required')
        return errors
    },
    locationZipcodeErrors(){
      const errors = []
      if (!this.$v.addlocation.zip_code.$dirty) return errors 
      !this.$v.addlocation.zip_code.required && errors.push('Zipcode is required')
        return errors
    },
    fullnameFirstErrors(){
      const errors = []
      if (!this.$v.company_name.$dirty) return errors 
      !this.$v.company_name.required && errors.push('Agency Name is required')
        return errors
    },
  },
  async mounted() {
    this.getUserDetails().then(()=>{
      this.getSocial().then(()=>{
        this.getLanguage().then(()=>{
          this.getLocation().then(()=>{
            this.getAssociatedFreelancer().then(()=>{
              this.getAgencyContract();
              var UserData = JSON.parse(localStorage.getItem("loginUserData"));
              this.login_master_id = UserData.login_master_id;
            })
          })
        })
      })
    });
    // this.startTime();
  },
  methods: {

    async getCurrentTime(city, country) {
      // World Time API supports country/city IANA format.
      // const cityFormatted = city.replace(/ /g, "_");
      const countryFormatted = country.replace(/ /g, "_");

      try {
        const response = await axios(`http://worldtimeapi.org/api/timezone/${countryFormatted}`);
        const data = await response.json();
        console.log(`Current time in ${city}, ${country} is ${data.datetime}`);
      } catch (error) {
        console.error("Error fetching time:", error);
      }
    },

    toggleIcon(index) {
      // Toggle the icon for the selected accordion item
      this.icons[index] = !this.icons[index];
    },
    // this fun is for to get filter name
    filterName(_i){
      var name;
      this.talentQualilty.map(data=>{
        if(data.talent_id == _i){
          name = data.talent_title;
          return false;
        }
      });
      return name;
    },
    // this fun is for to get the talent tag 
    getTalentImg(_i){
      var name;

      this.talentQualilty.map(data=>{
        if(data.talent_id==_i){
          name = data.img
          return false;
        }
      });
      return name;
    },
    // this fun is for to get the data of associated with agency
    async getAssociatedFreelancer() {
        var res =  await this.genericAPIPOSTRequest({
            requestUrl: "associatedFreelancer",
            params: {},
        });
        if(res){
          this.associatedFreelancerData = res;
          this.associatedFreelancerData.filter(data=>{
            if(data.business == 2){
              this.agencyManager.push(data);
            }else if(data.business != 2){
              this.agencyMember.push(data);
            }
          });
        }
        var tatentdata = await this.fetchTalentList({params:{"talent_type":"Badges"}});
        if(tatentdata.data.status == true){
          this.talentQualilty = tatentdata.data.result;
        }
    },
    // this fun is for to open add location model
    async addLocationModel(){
      this.getCountry();
      this.addlocation={
        country_id:'',
        state_id:'',
        city_id:'',
        address:'',
        zip_code:'',
        is_head_office :'',
        company_location_id:''
      };
    },
    // this fun is for to edit location 
    async editLocationmodel(data){
      var country = await this.genericAPIPOSTRequest({
          requestUrl: "common/getCountryList",
          params: {},
      });  
      this.CountryData = country;
      var state = await this.genericAPIPOSTRequest({
          requestUrl: "common/getStateList",
          params: { country_id: data.country_id },
      });  
      if(state.length > 0){
          this.stateData = state;
      }
      var city = await this.genericAPIPOSTRequest({
          requestUrl: "common/getCityList",
          params: { state_id: data.state_id },
      }); 
      if(city.length > 0){ 
          this.cityData = city;
      }
      this.addlocation.country_id=data.country_id;
      this.addlocation.state_id=data.state_id;
      this.addlocation.city_id=data.city_id;
      this.addlocation.address=data.address;
      this.addlocation.zip_code=data.zip_code
      this.addlocation.is_head_office=data.is_head_office;
      this.addlocation.company_location_id=data.company_location_id
      if (data.is_head_office == 1) {
        this.hideLocationpriOption = false;
      }else{
        this.hideLocationpriOption = true;
      }
      this.$bvModal.show('edit-location-modal');
    },
    
    ...mapActions(["genericAPIPOSTRequest","fetchTalentList","fetchCompanyAccountList"]),
    addLanguage(){
      this.proficiencyVal.push('');
      this.languageVal.push('');
    },
    removeLanguage(_i){
      this.proficiencyVal.splice(_i,1);
      this.languageVal.splice(_i,1);
    },
    // this fun is for to get language
    async getLanguage(){
      var res = await this.genericAPIPOSTRequest({
            requestUrl: "getLanguageList",
            params: {},
        });  
      this.languageData = res; 
    },
    startTime() {
      var timezone_name =  this.userFullDetails.timezone_name;
      if(timezone_name!='' && timezone_name != undefined){
          let options = {
              timeZone: timezone_name, 
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
          },
          formatter = new Intl.DateTimeFormat([], options);
          this.timeClock = formatter.format(new Date());
      }else{ 
          const today = new Date();
          let h = today.getHours();
          let m = today.getMinutes();
          let s = today.getSeconds();
          m = this.checkTime(m);
          s = this.checkTime(s);
          this.timeClock =  h + ":" + m + ":" + s;
      }
      setTimeout(this.startTime, 1000);
  }, 
    checkTime(i) {
        if (i < 10) {i = "0" + i}// add zero in front of numbers < 10
        return i;
    },
    uploadProfile(){
      $('.ankaCropper__saveButton').trigger( "click" );
    },
    // this fun for upload file
    async UploadFile(e,type){ 
      var getData = await this.genericAPIPOSTRequest({
          requestUrl: "uploadimageBase",
          params: {'url':e.croppedImageURI,'type':type},
      });

      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date +' '+ time;
                    
      if(type == 'agencyprofile'){
        this.profileImg = getData.file+'?'+dateTime;
        this.$bvModal.hide('img-modal');
        var currentData = this.$store.state.currentUserData;
        currentData.profile_picture_path = this.profileImg
        this.$store.commit("setcurrentUserData", currentData);
      }else{
        this.$bvModal.hide('img-banner-modal');
        this.bannerImg = '';
        console.log('before',this.bannerImg)
        $('.banner-agency-img').removeAttr('style');
        $('.banner-agency-img').css({
          'width': '100%',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center top',
          'height':'150px',
          'background-image': "url('"+getData.file+'?'+dateTime+"')",
        });
        // $('.banner-agency-img').css('background', 'url('+getData.file+'?'+dateTime+');')
        this.bannerImg = getData.file+'?'+dateTime;
        console.log('after',this.bannerImg)
      }
      this.getsCompanyCount();
      // await this.fetchCompanyAccountList({data: this.getLoginUserIdRequest()});
    },
    someAction(_v){
      _v
    },
    async addAbout(){
      this.$v.aboutYourself.$touch()
          if (!this.$v.aboutYourself.$invalid) {  
            await this.genericAPIPOSTRequest({
                requestUrl: "user/addUpdateAboutYourself",
                params: {
                        aboutYourself: this.aboutYourself, 
                },
            });
            this.$bvModal.hide('add-about');
            this.getSocial();
          }   
    },
    async  getSocial(){
      var res = await this.genericAPIPOSTRequest({
            requestUrl: "user/getSocial",
            params: {},
      }); 
      this.aboutYourselfVal = res.about_self_desc;
      this.tagLineVal       = res.tagline;
    },
    async  getNationality(){
        var res = await this.genericAPIPOSTRequest({
              requestUrl: "common/getNationality",
              params: {},
        }); 
        this.nationalityArray = res;
        this.nationalityVal = this.userFullDetails.nationality_id;
    }, 
    async  getAgencyContract(){
      try {
        var res = await this.genericAPIPOSTRequest({
          requestUrl: "getJobdataList",
          params: { filter: 6, type: this.$store.state.accountType },
        });
        if(res.length > 0){
          this.getCompletedJobList = res;
        }  

        var response = await this.genericAPIPOSTRequest({
          requestUrl: "getJobdataList",
          params: { filter: 2, type: this.$store.state.accountType },
        });
        if(response.length > 0){
          this.getPendingJobList = response;
        }  
      } catch (error) {
        console.log(error);
      }

    },
    // this fun is for to get user details
    async getUserDetails(){
      try {
        var userdata = JSON.parse(localStorage.getItem('loginUserData'));
        this.profileImg = userdata.profile_picture_path;
        var response = await this.genericAPIPOSTRequest({
            requestUrl: "user/getUserDetails",
            params: {
              "data"    : (this.$store.state.currentUserData.permission == null || this.$store.state.currentUserData.permission == '')?this.getLoginUserIdRequest():'',
              "usertype": (this.$store.state.accountType =='agency') ? 'agency':'',
              "user_id" : (this.$store.state.currentUserData.permission != null || this.$store.state.currentUserData.permission != '')?'ASS'+this.encodedid(this.$store.state.currentUserData.login_master_id):'',
            },
        });
        this.userFullDetails = response;
        this.companySettingData = this.userFullDetails.company_settings;
        this.addskills = this.companySettingData.company_skills;
        this.languageCount = this.userFullDetails.language_id;
        this.profileImg = this.$store.state.baseUrlImg+'/profile_image/'+response.profile_picture_path;
        this.bannerImg = this.$store.state.baseUrlImg+'/banner_image/'+this.userFullDetails.company_settings.company_banner_path;
        if(this.bannerImg != '' && this.bannerImg != null){
          $('.banner-agency-img').css({
            'background-image': "url('"+this.bannerImg+"')",
          });
        }else{
          $('.banner-agency-img').css({
            'background-color':'#F2613C!important',
          });
        }
        this.getUserReviewCal();
        this.$store.dispatch("profileImg", this.profileImg); 
      } catch (error) {
        console.log('error',error)
      }
    },
    getuserDetail(value){
        this.userFullDetails = value;
    },
    async getUserReviewCal(){
      var res = await this.genericAPIPOSTRequest({
            requestUrl: "getUserReviewCal",
            params: {},
      });
      this.userFullDetails.ratingSum =   res.ratingSum;
      this.userFullDetails.countRate =   res.countRate;
      this.userFullDetails.rating    =   res.rating;
    },
    Availability: function(user) {
        if (user) {
            if (user.availability == 1) {
                return 'Adhoc - Less than 30 hrs/week';
            } else if (user.availability == 2) {
                return 'Residence - More than 40 hrs/week';
            }  
        }
    },
    setAvailability(){
      this.availabilityVal  = this.userFullDetails.availability
    },
    async removeAboutUs(){
      this.aboutYourself = '';
      this.addAbout();
    },
    async updateUserProfile(name){
      var data = {};
      if(name=='companySize'){
        data = {'company_size':this.companySizevalue};
      }else if(name=='founded'){
        data = {'company_founded':this.companyfounded};
      }else if(name=='language'){
        // data = {'language_id':this.languageVal,'proficiency_id':this.proficiencyVal};
        var updatedata=[];
        for (let index = 0; index < this.languageVal.length; index++) {
          updatedata.push({
            'language_id':this.languageVal[index],
            'proficiency_id':this.proficiencyVal[index],
            'is_primary': (index == 0)?'Y':'N'
          })
        }
        await this.genericAPIPOSTRequest({
              requestUrl: "updateUserLanguage",
              params: {
                "data"    : this.getCurrentUserIdRequest(),
                "type"    : this.$store.state.accountType,
                "params"  : updatedata
              },
        });
        this.$bvModal.hide('modal-editlanguage'); 
        this.getUserDetails();
        return false;
      }else if(name=='yearOfExperience'){
        data = {'year_of_experience':this.yearOfExperience};
      }else if(name=='companySize' || name=='founded'){
        data.company_details_id = this.companySettingData.company_details_id;
        data.data = this.getCurrentUserIdRequest();
        await this.genericAPIPOSTRequest({
          requestUrl: "updateCompanydata",
          params: data,
        });
        return false;
      }else if(name == 'fullname'){
        console.log('aasdas',this.$v.companyNameUpdate.$invalid)
        if (!this.$v.companyNameUpdate.$invalid) {
          data = {'company_name':this.companyNameUpdate};
          await this.genericAPIPOSTRequest({
            requestUrl: "user/updateUserData",
            params: data,
          });
          this.userFullDetails.company_settings.company_name = this.companyNameUpdate;
          this.company_name = this.companyNameUpdate;
          // this.editName = false;
          this.$bvModal.hide('add-company-name');  
          return false;
        }else{
          console.log('this.companyNameUpdate',this.companyNameUpdate)
          return false;
        }

      }

      await this.genericAPIPOSTRequest({
        requestUrl: "user/updateUserData",
        params: data,
      });
      this.$bvModal.hide('modal-editcompanySize');
      this.$bvModal.hide('modal-editlanguage'); 
      this.$bvModal.hide('modal-editFounded');
      this.$bvModal.hide('modal-editYearsofExperience');  
      this.$bvModal.hide('add-company-name');  
      
      this.getUserDetails();
    },
      
    setLanguage(){
      this.proficiencyVal = this.userFullDetails.proficiency_id;
      this.languageVal = this.userFullDetails.language_id;
    }, 
    setPrimary(status){
      this.is_primary = status;
    },
    setAbout(){ 
      this.aboutYourself = this.aboutYourselfVal;
    },
    async addTagLine(){
        this.$v.tagLineself.$touch()
        if (!this.$v.tagLineself.$invalid) {  
          await this.genericAPIPOSTRequest({
              requestUrl: "user/addUpdateTagLine",
              params: {
                tagLineself: this.tagLineself, 
              },
          });
          this.$bvModal.hide('add-tagLine');
          this.getSocial();
        }   
    },
    setTagLine(){
      this.tagLineself = this.tagLineVal;
    },
    setCompanyLine(_v){
      this.companyNameUpdate = _v;
    },
    // this fun is for to get company size
    async getCompanysize(){ 
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "getCompanysize",
            params: {},
        });  
        this.companysize  = res;
    } ,
    // this fun is for to add or update Company founded
    async addupdateFounded(){
        this.$v.companyfounded.$touch()
        if (!this.$v.companyfounded.$invalid) {  
          this.updateUserProfile('founded');
        }   
    },
    // this fun is for to add or update agency size
    async addupdateagencySize(){
        this.$v.companySizevalue.$touch()
        if (!this.$v.companySizevalue.$invalid) {  
          this.updateUserProfile('companySize');
        }   
    },
    // this fun is for to get location data
    async getLocation(){
      var res = await this.genericAPIPOSTRequest({
          requestUrl: "getCompanyLocation",
          params: {},
      });  
      if(res){
        this.locationData = res;
      }
    },
    // this fun is for to get Country
    async getCountry(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "common/getCountryList",
            params: {},
        });  
        this.CountryData = res;
        this.addlocation.country_id = '';
        this.addlocation.state_id = '';
        this.addlocation.city_id = '';
    },

    // THIS FUNCTIONIS FOR GET STATE DATA
    async changecountry(event){
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "common/getStateList",
            params: { country_id: event },
        });  
        if(res.length > 0){
            this.stateData = res;
        }
        this.cityData =[];
        this.addlocation.state_id = '';
        this.addlocation.city_id = '';
    },
    // THIS FUNCTIONIS FOR GET CITY DATA
    async changestate(event){
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "common/getCityList",
            params: { state_id: event },
        });  
        this.cityData = res;
        this.addlocation.city_id = '';
    },

    // this fun is for add update location
    async addupdateLocation(){
      this.$v.addlocation.$touch()
        if (!this.$v.addlocation.$invalid) {
          var res = await this.genericAPIPOSTRequest({
              requestUrl: "addUpdateCompnayLocations",
              params: {
                location : this.addlocation,
              },
          }); 
          if (res) {
            this.getUserDetails();
            this.getLocation();
            if (this.locationModelTitle=='Add Location') {
              this.$toastr.s('Location successfully Added');
            } else {
              this.$toastr.s('Location successfully Updated');
            }
            this.hideLocationpriOption = true;
            this.$bvModal.hide('edit-location-modal');
          }
        }

    },
    // this fun is for to remove loacation 
    async removeLocation(_i){
      var res = await this.genericAPIPOSTRequest({
          requestUrl: "removeCompnayLocations",
          params: {
            company_location_id : _i,
            status : '1',
          },
      }); 
      if (res) {
        this.getUserDetails();
        this.getLocation();
        this.$toastr.s('Location Removed successfully');
        this.$bvModal.hide('confirm-modal');
      }
    },
    // this fun is for to remove skills
    removefromCategoryarr(index){
      if(this.companySettingData.company_skills){
        this.companySettingData.company_skills.splice(index, 1); 
        this.addskills= this.companySettingData.company_skills;
        this.addSkillData();
        // \\OR   
        // this.$delete(this.category_data,index);
        // \\both will do the same
      }
    },
    // this fun is for to get skills data
    async getSkills(){
      var res = await this.genericAPIPOSTRequest({
          requestUrl:'common/getSkills',
          params:{}
      });
      if(res.length > 0 ){
        this.skillsData = res
      }
    },

    // this fun is for to add skills

    async addSkillData(){
      if(this.addskills.length > 0){
        var res = await this.genericAPIPOSTRequest({
          requestUrl:'updateCompanydata',
          params:{
            company_skills: this.addskills,
            company_details_id: this.companySettingData.company_details_id,
            data:this.getCurrentUserIdRequest()
          }
        });
        if(res){
          this.getUserDetails();
          this.$toastr.s('Skill Added Successfully');
          this.$bvModal.hide('modal-addSkills');
          this.$bvModal.hide('modal-addSkills-new');
        }
      }
    },
  },
}
</script>
<!-- <style src="@vueform/multiselect/themes/default.css"></style> -->
<style>
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
  .btn-small{padding: 4px 8px!important;}
  .btn-small:hover{padding: 4px 8px!important;}
  .ankaCropper__saveButton{
    /* display:none!important; */
  }
  body {
    font-family: 'Source Sans Pro', sans-serif !important;
  }
  .text-muted{color:#000!important;}
  .ralative {
    padding-bottom: 0px;
  }
  .theme-bg {
    background-color: #fff6f4 !important;
  }
  .bg-none {
    background-color: transparent;
  }
  
  .br-20 {
    border-radius: 20px;
  }
  .border-none {
    border: 0px !important;
  }
  
  .freelancer-wrap .fz-14 {
    font-size: 14px;
  }
  .freelancer-wrap .card {
    border-radius: 20px !important;
  }
  .freelancer-wrap .card-body {
    border-radius: 20px;
  }
  .freelancer-wrap .card-body {
    border-radius: 0 0 20px 20px;
  }
  .freelancer-wrap .card-header {
    border-radius: 20px 20px 0 0 !important;
  }
  .fz-16 {
    font-size: 16px;
  }
  .fz-18 {
    font-size: 18px;
  }
  .fz-24 {
    font-size: 24px;
  }
  .fw-600 {
    font-weight: 600;
  }
  .mb-23 {
    margin-bottom: 23px;
  }
  .no-bottom-border {
    border-bottom: 0px !important;
  }
  .border-top-0 {
    border-top: 0px !important;
  }
  .fz-34 {
    font-size: 34px;
  }
  .mb-50 {
    margin-bottom: 50px !important;
  }
  .profile-wrap .img-wrapper {
        /* box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    height: 90px;
    width: 90px;
    padding: 3PX;
    BORDER: 1px solid #eee;
    position: relative;
  }
 
  .profile-wrap .content ul {
    margin-left: -35px;
    display: inline-flex;
    align-items: center;
  }
  .profile-wrap .content ul li {
    margin-right: 5px;
  }
  .profile-wrap .star-rating {
    display: flex;
    font-size: 1.5em;
    justify-content: space-between;
    padding: 0;
    text-align: center;
    width: 4em;
  }
  .profile-wrap .star-rating input {
    display: none;
  }
  .profile-wrap .star-rating label {
    color: #d4d5cf;
  }
  .profile-wrap .star-rating label.active {
    color: #f2613c;
  }
  .profile-wrap input ~ label:before {
    display: none;
  }
  .badge-theme {
    background: rgba(242, 97, 60, 0.1);
    border-radius: 6px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #f2613c !important;
    padding: 6px 12px !important;
  }
  .profile-wrap .card-body {
    padding-top: 0px !important;
  }
  p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
  }
  dl,
  ol,
  ul {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  ol,
  ul {
    padding-left: 2rem !important;
  }
  hr {
    margin: 1rem 0 !important;
  }
  .fz-30 {
    font-size: 26px !important;
  }
  .pro-content {
    max-width: 700px;
    margin: 0 auto;
  }
  .border-bottom:last-child {
    border-bottom: none !important;
  }
  .full-width-wrapper .card-body {
    padding: 0px;
  }
  .work-filled .star-rating label.active {
    color: #ffd260 !important;
  }
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  .work-filled .work-section {
    border-bottom: 1px solid #dee2e6 ;
  }
  .work-filled .work-section:nth-last-child() {
    border-bottom: 0px;
  }
  
  .modal select {
    -webkit-appearance: menulist !important; /* override vuetify style */
    -moze-appearance: menulist !important; /* override vuetify style */
    appearance: menulist !important; /* override vuetify style */
  }
  .other-information .tags span {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 100px;
    padding: 6px 15px !important;
    font-size: 14px;
    --skill-height: 30px;
    margin: 0 5px 10px 0;
    padding: 0 10px;
    border-radius: 15px;
    font-size: 12px;
    line-height: 1.5;
    cursor: default;
    color: #666666;
    display: inline-block;
    font-weight: 600;
  }
  .education-section .details {
    margin-left: 87px;
  }
  .accordion {
    border: 1px solid #e4e4e4;
    border-radius: 0.25rem;
    padding: 12px;
  }
  .accordion .btn-secondary {
    color: #000 !important;
    background-color: #fff !important;
    width: 100%;
    text-align: left;
    border: 0px;
    padding: 10px 10px;
  }
  .accordion .btn-check:focus + .accordion .btn-secondary,
  .accordion .btn-secondary:focus {
    box-shadow: none;
    border-color: transparent;
  }
  .accordion .card-body {
    padding: 0 10px;
  }
  .list-style-disc{list-style: disc;}
  /*.list-group-item:first-child{padding-bottom:.8rem!important;}*/
  .line-break-anywhere{line-break:anywhere;}
  .ankaCropper.light {
      background: #0f1114;
      color: #eee;
  }
  
  .ankaCropper.light .ankaCropper__droparea {
      border: 2px dashed #3e424b;
      background-color: #fff;
      color: #000;
      text-align: center;
      padding: 50px;
  }
  
  .ankaCropper.light .ankaCropper__saveButton{
    background: #334f90;
      color: #fff;
      float: right;
      padding: 10px;
      align-items: center;
      display:flex;
  }
  .ankaCropper.light .ankaCropper__selectButton {
      background: #fff;
      color: #334f90;
      font-weight: 600;
  }
  
  .ankaCropper.light .ankaCropper__selectButton:hover{
    background: #fff;
      color: #334f90;
      font-weight: 600;
  }
  
  .ankaCropper.light .ankaCropper__saveButton:hover {
      background: #335dbe;
      display:inline-flex;
      align-items-center:center;
  }
  
  .ankaCropper.light .ankaCropper__navigation {
      background: #1d2227;
      display: inline-block;
      width: 100%;
  }
  
  .ankaCropper.light .ankaCropper__navButton {
      background: #272c31;
      display: inline-block;
      padding: 14px;
  }
  
  .ankaCropper.light .ankaCropper__navButton svg {
      stroke: #eee;
  }
  
  .ankaCropper.light .ankaCropper__navButton:hover {
      background: #000;
  }
  .ankaCropper__mainArea div:first-child{width:100%!important;}
  .ankaCropper__mainArea div canvas{width:100%!important;}
  .ankaCropper__previewArea{
    width:150px!important;height:100px!important;padding:6px 12px!important;    margin-top: 30px;
  }
  .ankaCropper__previewArea img{
    width:100%!important;height:100%!important;border-radius:13px!important;
  }
  .btn-group{
    float: right;
    margin-top: 86px;
  }
  .ankaCropper.light .ankaCropper__saveButton {
      background: #334f90;
      color: #fff;
      float: right;
      padding: 10px;
      align-items: center;
      display: flex;
      border-radius: 6px!important;
      background-color: #F2613C!important;
      box-shadow: 0 10px 25px 0 rgb(0 0 0 / 10%)!important;
      border: 1px solid #F2613C!important;
      color: #ffffff!important;
      font-family: "Source Sans Pro"!important;
      font-size: 14px!important;
      font-weight: 600!important;
      line-height: 20px!important;
      text-align: center!important;
      padding: 8px 12px!important;
      position: absolute;
      bottom: 16px;
      /* bottom: 22px; */
      right: 16px;
      z-index: 1;
  }
  .ankaCropper__saveButton svg{display:none;}
  .remove-btn-pop{ margin-top: 30px!important; padding: 11px 12px!important;}
  /*@media only screen and (min-width: 320px) and (max-width: 815px) {
    .text-sm-right{text-align:left!important;}
    .text-sm-center{text-align:center!important;}
    .m-sm-auto{margin:auto!important;}
    .top-rated-edit svg{margin:auto;}
  }*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .office-locations-scroll{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .multiselect__content{
    padding-left: 0px !important;
  }
</style>
