<template>
    <b-container>
        <b-row class="mt-100">
            <b-col xl="12" lg="12" sm="12">
                <h3 class="fz-24 text-black fw-600 mb-0">{{jobDetails.job_title}}</h3>
            </b-col>
        </b-row>
        <b-row class="mb-0">
            <b-col xl="9" lg="9" sm="9" class="left">
                <b-card-text>
                    <div class="view-wrapper">
                        <div class="header px-4 py-4">
                            <div class="d-md-flex align-items-center w-100">
                                <div>
                                <h5 class="mb-3 text-theme">{{jobDetails.quality_inspector}}</h5>
                                <p class="mb-0 fz-16">Posted {{jobDetails.time}}</p>
                                </div>
                                <div class="ml-auto d-flex align-items-center">
                                <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0009 5.28052C8.37861 5.28052 5.41016 8.40161 5.41016 12.2751C5.41016 14.3588 6.46939 16.8773 8.23885 19.662C8.85375 20.6296 9.51649 21.566 10.1864 22.4407C10.6064 22.9891 10.953 23.4151 11.1795 23.6803C11.6252 24.2023 12.3769 24.202 12.8224 23.6803C13.0489 23.4151 13.3955 22.9891 13.8155 22.4407C14.4854 21.566 15.1481 20.6296 15.763 19.662C17.5325 16.8773 18.5917 14.3588 18.5917 12.2751C18.5916 8.40159 15.6232 5.28052 12.0009 5.28052ZM9.18957 18.8473C7.5603 16.2832 6.59169 13.9802 6.59169 12.2751C6.59169 9.19148 9.00641 6.6526 12.0009 6.6526C14.9954 6.6526 17.4101 9.19148 17.4102 12.2751C17.4102 13.9802 16.4416 16.2832 14.8123 18.8473C13.7973 20.4446 12.7487 21.8158 12.0009 22.6936C11.2531 21.8158 10.2046 20.4446 9.18957 18.8473ZM12.0009 14.761C10.7682 14.761 9.77235 13.5705 9.77235 12.1145C9.77235 10.6584 10.7683 9.46794 12.0009 9.46794C13.2336 9.46794 14.2295 10.6585 14.2295 12.1145C14.2295 13.5705 13.2336 14.761 12.0009 14.761ZM12.0009 13.5665C12.6588 13.5665 13.2009 12.9184 13.2009 12.1145C13.2009 11.3105 12.6588 10.6624 12.0009 10.6624C11.3431 10.6624 10.8009 11.3105 10.8009 12.1145C10.8009 12.9184 11.3431 13.5665 12.0009 13.5665Z" fill="#999999"/>
                                </svg>
                                <p class="mb-0 fz-16">{{jobDetails.country_name+', '+jobDetails.state_name+', '+jobDetails.city_name}}</p>
                                </div>
                            </div>
                            </div>
                            <div class="content px-4 py-4" v-if="jobDetails!=''">
                            <p class="fw-400 fz-16 mb-0 text-muted">
                                <read-more more-str="Read more" :text="getHtmlContent(jobDetails.scope_of_work)" link="#" less-str="Read less" :max-chars="500"></read-more>
                            </p>
                            </div>
                            <div class="content px-4 py-4" v-if="jobDetails!=''">
                            <div>
                                <ul  class="ml-2 pl-0 mt-4 attachment"  v-if="jobDetails.attachment.length > 0" style="padding: 6px 0px;">
                                <template v-for="(attc, index) in jobDetails.attachment">
                                    <li class="attatchment-wrap mr-5 mb-2 d-flex align-items-center"  :key="index" v-if="index >= 0" style="border-radius: 20px;padding: 2px 10px;margin-right: 5px;">
                                    <i class="fa fa-paperclip border-none text-black fz-18 rotate-45"></i>
                                    <a :href="jobDetails.attachmentUrl+'/'+attc" target="_blank"><span class="ml-2 text-theme">{{attc}}</span></a>
                                    </li>
                                </template>
                                </ul>
                            </div>
                            </div>
                            <div class="job-scope px-4 py-4">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                <div class="box-wrapper d-flex align-items-center">
                                    <div class="img-wrapper mr-2">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1073_14916)">
                                        <path d="M12.4166 0.500244H7.91394C7.61608 0.500244 7.19977 0.672804 6.98932 0.8831L0.816135 7.05625C0.394622 7.47728 0.394622 8.16759 0.816135 8.58811L5.41257 13.1844C5.83313 13.6054 6.52277 13.6054 6.94396 13.1839L13.1171 7.01172C13.3274 6.80145 13.5 6.38448 13.5 6.08725V1.58362C13.5 0.987899 13.0123 0.500244 12.4166 0.500244ZM10.2498 4.8336C9.6515 4.8336 9.16645 4.34804 9.16645 3.75022C9.16645 3.15142 9.6515 2.66684 10.2498 2.66684C10.8482 2.66684 11.3334 3.15142 11.3334 3.75022C11.3334 4.34804 10.8482 4.8336 10.2498 4.8336Z" fill="#F2613C"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1073_14916">
                                        <rect width="13" height="13" fill="white" transform="translate(0.5 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                    <div class="box-content">
                                    <h5 class="fz-16 mb-0"><strong>{{(jobDetails.budget_type=='A')?'Daily Rate':'Monthly Rate'}}</strong></h5>
                                    <small class="mb-0 text-muted">{{jobDetails.budget}} {{(jobDetails.budget_type=='A')?'Per Day':'Per Month'}}</small>
                                    </div>
                                </div>  
                                </div>
            
                                <div class="col-lg-6 col-md-12">
                                <div class="box-wrapper d-flex align-items-center">
                                    <div class="img-wrapper mr-2">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1073_14916)">
                                        <path d="M12.4166 0.500244H7.91394C7.61608 0.500244 7.19977 0.672804 6.98932 0.8831L0.816135 7.05625C0.394622 7.47728 0.394622 8.16759 0.816135 8.58811L5.41257 13.1844C5.83313 13.6054 6.52277 13.6054 6.94396 13.1839L13.1171 7.01172C13.3274 6.80145 13.5 6.38448 13.5 6.08725V1.58362C13.5 0.987899 13.0123 0.500244 12.4166 0.500244ZM10.2498 4.8336C9.6515 4.8336 9.16645 4.34804 9.16645 3.75022C9.16645 3.15142 9.6515 2.66684 10.2498 2.66684C10.8482 2.66684 11.3334 3.15142 11.3334 3.75022C11.3334 4.34804 10.8482 4.8336 10.2498 4.8336Z" fill="#F2613C"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1073_14916">
                                        <rect width="13" height="13" fill="white" transform="translate(0.5 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                    <div class="box-content">
                                    <h5 class="fz-16 mb-0"><strong>{{jobDetails.employment_Text}}</strong></h5>
                                    <small class="mb-0 text-muted">{{jobDetails.employment_Name}}</small>
                                    </div>
                                </div>  
                                </div>
                                
                                <div class="col-lg-6 col-md-12">
                                <div class="box-wrapper d-flex align-items-center">
                                    <div class="img-wrapper mr-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1073_14884)">
                                        <path d="M9.31831 8.43972C9.31831 9.65205 8.332 10.6384 7.11967 10.6384C5.90733 10.6384 4.92102 9.65205 4.92102 8.43972C4.92102 7.22735 5.90733 6.24102 7.11967 6.24102C7.56724 6.24102 7.98378 6.37578 8.3315 6.60638L9.60656 5.33132C9.57779 5.31028 9.54888 5.28934 9.51946 5.26877C8.65476 4.66452 7.78857 4.35913 6.94128 4.35913C6.86367 4.35913 6.78627 4.36168 6.70898 4.36681C5.68664 4.43639 4.7342 4.89151 4.02776 5.64828C3.31957 6.40572 2.92969 7.39695 2.92969 8.43972C2.92969 9.55851 3.36562 10.6106 4.15717 11.4022C4.94877 12.1938 6.00085 12.6297 7.11964 12.6297C8.23843 12.6297 9.29054 12.1937 10.0821 11.4022C10.8737 10.6106 11.3096 9.55851 11.3096 8.43972C11.3096 7.5188 10.9377 6.63468 10.2662 5.91453L8.95289 7.22785C9.18357 7.57558 9.31831 7.99215 9.31831 8.43972Z" fill="#F2613C"/>
                                        <path d="M6.80781 8.12916L7.68766 7.24932C7.51524 7.16655 7.32224 7.12012 7.11856 7.12012C6.39086 7.12012 5.79883 7.71218 5.79883 8.43991C5.79883 9.16762 6.39086 9.75965 7.11856 9.75965C7.84627 9.75965 8.4383 9.16762 8.4383 8.43991C8.4383 8.23624 8.39187 8.0432 8.30913 7.87079L7.42932 8.75061L6.80781 8.12916Z" fill="#F2613C"/>
                                        <path d="M11.4341 4.74597L10.8873 5.29276C11.7235 6.17815 12.1876 7.28158 12.1876 8.43984C12.1876 9.79341 11.6603 11.0662 10.7027 12.0238C9.74514 12.9813 8.47233 13.5087 7.11879 13.5087C5.76524 13.5087 4.49244 12.9813 3.53484 12.0238C2.57725 11.0662 2.0499 9.79338 2.0499 8.43984C2.0499 7.17339 2.52396 5.96897 3.38467 5.0484C4.24345 4.12836 5.40293 3.5748 6.64919 3.48998C7.77568 3.41554 8.91006 3.77141 10.022 4.54848C10.0942 4.59893 10.1644 4.6509 10.2333 4.70381L10.8127 4.12449L10.9054 2.47827C9.78413 1.76296 8.48098 1.37964 7.11882 1.37964C5.23298 1.37964 3.45999 2.11402 2.12651 3.4475C0.792978 4.78098 0.0585938 6.55397 0.0585938 8.43981C0.0585938 10.3256 0.792979 12.0986 2.12648 13.4321C3.45999 14.7656 5.23295 15.5 7.11879 15.5C9.00463 15.5 10.7776 14.7656 12.1111 13.4321C13.4446 12.0986 14.179 10.3256 14.179 8.43981C14.179 7.07765 13.7956 5.77449 13.0803 4.65318L11.4341 4.74597Z" fill="#F2613C"/>
                                        <path d="M11.709 3.85021L13.5048 3.74899L15.0592 2.19459L13.3646 0.5L11.8102 2.05437L11.709 3.85021Z" fill="#F2613C"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1073_14884">
                                        <rect width="15" height="15" fill="white" transform="translate(0.0585938 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                    <div class="box-content">
                                    <h5 class="fz-16 mb-0"><strong>Scope</strong></h5>
                                    <small class="mb-0 text-muted">{{jobDetails.project_type+', '+jobDetails.project_length_title}}</small>
                                    </div>
                                </div>  
                                </div>
            
                                <div class="col-lg-6 col-md-12">
                                <div class="box-wrapper d-flex align-items-center">
                                    <div class="img-wrapper mr-2">
                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6562 11.25H11.3242C11.3242 12.4562 10.2816 13.4375 9 13.4375C7.71843 13.4375 6.67578 12.4562 6.67578 11.25H6.34375C4.23831 11.25 2.52539 12.8622 2.52539 14.8438V15.5312C2.52539 15.7901 2.74838 16 3.02344 16H14.9766C15.2516 16 15.4746 15.7901 15.4746 15.5312V14.8438C15.4746 12.8622 13.7617 11.25 11.6562 11.25Z" fill="#F2613C"/>
                                        <path d="M7.67188 10.7812V11.25C7.67188 11.9403 8.26651 12.5 9 12.5C9.73349 12.5 10.3281 11.9403 10.3281 11.25V10.7812H7.67188Z" fill="#F2613C"/>
                                        <path d="M9 1.875C9.27505 1.875 9.49805 1.66513 9.49805 1.40625V0.46875C9.49805 0.209875 9.27505 0 9 0C8.72495 0 8.50195 0.209875 8.50195 0.46875V1.40625C8.50195 1.66513 8.72495 1.875 9 1.875Z" fill="#F2613C"/>
                                        <path d="M5.24649 1.54035L5.74454 2.35225C5.88207 2.57644 6.18668 2.65325 6.42488 2.52382C6.66308 2.39438 6.74469 2.10769 6.60716 1.8835L6.10911 1.0716C5.97159 0.847411 5.66698 0.770598 5.42878 0.900036C5.19058 1.02947 5.10897 1.31616 5.24649 1.54035Z" fill="#F2613C"/>
                                        <path d="M2.99909 3.99975L3.86174 4.4685C4.09994 4.59794 4.40455 4.52113 4.54207 4.29694C4.6796 4.07275 4.59799 3.78607 4.35979 3.65663L3.49714 3.18788C3.25894 3.05844 2.95433 3.13525 2.81681 3.35944C2.67928 3.58363 2.76089 3.87032 2.99909 3.99975Z" fill="#F2613C"/>
                                        <path d="M14.5004 3.18788L13.6378 3.65663C13.3996 3.78607 13.318 4.07275 13.4555 4.29694C13.593 4.52113 13.8976 4.59794 14.1358 4.4685L14.9985 3.99975C15.2366 3.87032 15.3183 3.58363 15.1807 3.35944C15.0432 3.13525 14.7386 3.05844 14.5004 3.18788Z" fill="#F2613C"/>
                                        <path d="M11.5714 2.52382C11.8096 2.65325 12.1142 2.57644 12.2517 2.35225L12.7497 1.54035C12.8873 1.31616 12.8056 1.02947 12.5674 0.900036C12.3292 0.770598 12.0246 0.847411 11.8871 1.0716L11.3891 1.8835C11.2515 2.10769 11.3332 2.39438 11.5714 2.52382Z" fill="#F2613C"/>
                                        <path d="M9 2.8125C6.98288 2.8125 5.34766 4.35153 5.34766 6.25C5.34766 7.47166 6.02586 8.54234 7.04649 9.15131C7.32015 9.31459 7.51914 9.56103 7.61241 9.84375H8.50195V7.38166L7.65172 6.58147C7.45722 6.39841 7.45722 6.10162 7.65172 5.91856C7.84622 5.7355 8.16155 5.7355 8.35606 5.91856L9 6.52459L9.64391 5.91856C9.83841 5.7355 10.1537 5.7355 10.3482 5.91856C10.5427 6.10162 10.5427 6.39841 10.3482 6.58147L9.49805 7.38166V9.84375H10.3876C10.4809 9.56103 10.6799 9.31459 10.9535 9.15131C11.9741 8.54234 12.6523 7.47166 12.6523 6.25C12.6523 4.35153 11.0171 2.8125 9 2.8125Z" fill="#F2613C"/>
                                    </svg>
                                    </div>
                                    <div class="box-content">
                                    <h5 class="fz-16 mb-0"><strong>{{jobDetails.experience_level_title}}</strong></h5>
                                    <small class="mb-0 text-muted">{{jobDetails.experience_level_description}}</small>
                                    </div>
                                </div>  
                                </div>
                            </div>
                            </div>
                            <div class="proposal px-4 py-4" v-if="jobDetails.preference_questions">
                            <!-- <div class="proposal px-4 py-4" v-if="jobDetails.preference_questions.length > 0"> -->
                            <h5 class="mb-3 fz-18 fw-600">You will be asked to answer the following questions when submitting a proposal:</h5>
                            <p class="mb-3 fz-16" v-for="(ques,ids) in jobDetails.preference_questions" :key="ids">{{ids+1}}. {{ques.text}}</p>
                            </div>
                            <div class="row px-4 py-4 activity">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="mb-3 fz-18 fw-600">Preferred Qualifications</h5>
                                <ul class="custom-margin">
                                <li class="text-muted fz-16 mb-1 fw-600" v-if="jobDetails.preference_languages_arr"><span>Other languages:</span></li>
                                <li class="text-muted fz-16 mb-1 fw-600"><span v-for="(other_lang, id) in jobDetails.preference_languages_arr" :key="id">{{(id!=0)?', ':' '}}{{other_lang.value}}</span></li>
                                <li class="text-muted fz-16 mb-1 fw-600"><span>English:</span> {{jobDetails.english_level}}</li>
                                </ul>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <h5 class="mb-3 fz-18 fw-600">Activity on this job</h5>  
                                <ul class="custom-margin">
                                    <b-card-text class="text-muted fz-16 mb-1 fw-600"><span>Proposals:</span>  {{jobApplicationStatus.jobApplicantCount}}</b-card-text>
                                    <b-card-text class="text-muted fz-16 mb-1 fw-600">Total Applicants: {{jobActivities.total_application}}</b-card-text>
                                    <!-- <b-card-text class="text-muted fz-16 mb-1 fw-600">Last Viewed by client: <span class="fz-14 mb-1 fw-600">3 days ago</span></b-card-text> -->
                                    <b-card-text class="text-muted fz-16 mb-1 fw-600">Interviewing: <span class="fz-14 mb-1 fw-600">{{jobActivities.interviewing}}</span></b-card-text>
                                    <b-card-text class="text-muted fz-16 mb-1 fw-600">Invites Sent: <span class="fz-14 mb-1 fw-600">{{jobActivities.send_invites}}</span></b-card-text>
                                    <b-card-text class="text-muted fz-16 mb-1 fw-600">Unanswed invites: <span class="fz-14 mb-1 fw-600">{{jobActivities.unanswerd_invites}}</span></b-card-text>
                                </ul>
                            </div>
                            <div class="job-inner mb-5">
                                <h4 class="fz-18 fw-600 mb-3">Skills</h4>
                                <div class="tags-wrap">
                                <ul class="custom-margin">
                                    <li v-for="(skill,ids) in jobDetails.skill_name" :key="ids">
                                    <a href="javascript:void(0);" class="d-flex align-items-center fz-14">
                                        <span class="mr-2">{{skill}}</span> 
                                    </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                    </div>
                </b-card-text>
            </b-col>
            <b-col xl="3" lg="3" sm="3" class="right">
                <b-card-text v-if="$store.state.currentUserData == ''">
                    <div class="view-wrapper">
                        <div class="summary px-4 py-4">
                            <h4><b>Explore Qapin opportunities for free</b></h4>
                            <b-button class="btn btn-theme w-100" @click="$router.push('/sign-up').catch(()=>{})">Sign up</b-button>
                            <p class="text-muted fz-14 mt-2 text-center mb-0">Already have an account? <span class="text-theme cursor-pointer" @click="$router.push('/login').catch(()=>{})">Sign in</span></p>
                        </div>
                    </div>
                </b-card-text>
                <b-card-text>
                    
                    <div class="view-wrapper">
                        <div class="bordered-bottom py-3 px-20 text-center" v-if="$store.state?.currentUserData !='' && ($store.state?.accountType == 'freelancer' || $store.state?.accountType =='agency')">
                            <b-button class="btn-theme btn-block mb-2 w-100" v-if="isPermissionForAgency('apply-job') && proposal_btn && (inviteData=='' || inviteData.action == 1)" @click="submitProposal(jobDetailsWithClientData.jobDetails.jobs_id)">Submit a Proposal</b-button><br>
                            <b-button class="btn-outline-theme btn-block w-100" v-on:click="savePost(jobDetailsWithClientData.jobDetails,0)" >Save Job</b-button>
                        </div>

                        <div class="bordered-bottom py-3 px-20 text-center" v-else-if="$store.state?.currentUserData !='' && ($store.state?.accountType == 'client')">
                            <b-button class="btn-outline-theme btn-block w-100"  @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})" v-if="isPermissionForClient('post-job')" >Post a Job Like This</b-button>
                        </div>
                        <div class="summary px-4 py-4">
                            <div class="summary-inner mb-4">
                                <h4 class="fz-18 fw-600">About the client </h4>
                                <div class="star-rating mb-3">
                                <label for="5-stars" class="star" :class="(ratingsData >= 1)?'active':''"><i class="fa fa-star"></i></label>
                                <label for="4-stars" class="star" :class="(ratingsData >= 2)?'active':''"><i class="fa fa-star"></i></label>
                                <label for="3-stars" class="star" :class="(ratingsData >= 3)?'active':''"><i class="fa fa-star"></i></label>
                                <label for="2-stars" class="star" :class="(ratingsData >= 4)?'active':''"><i class="fa fa-star"></i></label>
                                <label for="1-star" class="star" :class="(ratingsData == 5)?'active':''"><i class="fa fa-star"></i></label>
                                </div>
                            </div>
                            <div class="summary-inner mb-4">
                                <h4 class="fz-16 fw-600">{{userDetails.postedJob}}  Jobs Posted</h4>
                                <p class="fz-16 fw-500">{{userDetails.hireRate}}% Hire Rate, {{userDetails.openJob}} Open Jobs</p>
                            </div>
                            <div class="summary-inner mb-4">
                                <h4 class="fz-16 fw-600">${{changeAmountvalue(userDetails.totalSpent)}} Total Spent</h4>
                                <p class="fz-16 fw-500">{{userDetails.hired}} {{(userDetails.hired > 0)?"Hire's":'Hire'}}</p>
                            </div>
                            <div class="summary-inner">
                                <p class="mb-0 fz-16 fw-600">Member Since {{userDetails.member_since}} </p>
                            </div>
                        </div>
                    </div>
                </b-card-text>
            </b-col>
        </b-row>
        <b-row class="mt-0 freelancer-wrap freelancer-job-detail" v-if="similarJobsData.length > 0" >
            <b-col xl="9" lg="9" sm="12">
                <b-card-group deck>
                <b-card title-class="text-theme font-14" header-tag="header" footer-tag="footer" header-class="py-4 px-4 bg-white">
                    <template  #header>
                    <h4 class="mb-0 fz-24 fw-500 text-black"><b>Similar Jobs on Qapin</b></h4>
                    </template>
                    <b-row>
                    <b-col cols="12" class="py-4 px-4">
                        <ul class="similar-jobs">
                        <li class="mb-3" v-for="(similarJob,id) in similarJobsData" :key="id"><a :href="$router.resolve({name: $store.state.accountType+'jobDetails',  path:'/'+$store.state.accountType+'/job-details', query: {jobDetailsWithClientData: similarJob.jobs_id}}).href"><span class="text-theme">{{similarJob.job_title}}</span></a> | <span> {{similarJob.country_name+', '+similarJob.state_name+', '+similarJob.city_name}}.</span>
                        </li>
                        </ul>
                    </b-col>
                    </b-row>
                </b-card>
                </b-card-group>
            </b-col>
        </b-row>
    </b-container>
  </template>
  <script>
    import { mapActions } from "vuex";
    export default {
      data() {
        return {
            inviteData                  : '',
            proposal_btn                : true,
            jobDetails : '',
            similarJobsData : [],
            jobApplicationStatus : {
                jobApplicantCount : 0,
            },
            jobDetailsWithClientData    : {
                jobDetails                    : {
                    attachment                    : [],
                },
            },
            jobActivities : '',
            ratingsData:0,
            userDetails:'',
            jobId:'',
            selected: null,
            transaction: [
                { value: null, text: 'All Transaction' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Selected Option' },
                { value: { C: '3PO' }, text: 'This is an option with object value' },
                { value: 'd', text: 'This one is disabled', disabled: true }
            ]
        } 
      },
      metaInfo() {
        return {
        title: this.jobDetails.job_title,
            meta: [
                // {
                //     property: 'title',
                //     content: this.jobDetails.job_title,
                // },
                {
                name: 'description',
                content: this.jobDetails.scope_of_work,
                },
                {
                property: 'og:title',
                content: this.jobDetails.job_title,
                },
                {
                property: 'og:description',
                content: this.jobDetails.scope_of_work,
                },
                // Uncomment if you need OG image and URL
                // {
                //   property: 'og:image',
                //   content: this.jobDetails.image || 'https://example.com/default-image.jpg',
                // },
                // {
                //   property: 'og:url',
                //   content: window.location.href,
                // },
            ],
            };
        },
      methods: {
        ...mapActions(["genericAPIPOSTRequest"]),

        // this fun for to go job proposal
        submitProposal(datas) {
        this.$router.push({
            name: this.$store.state.accountType+'submitProposal',
            path:'/'+this.$store.state.accountType+'/submit-proposal',
            query: {jobDetailsWithClientData: datas}
            }).catch(()=>{});

        },
        //this fun for to save & unsave post
        async savePost(jobdata){
            var addTobookMark = await this.genericAPIPOSTRequest({
                    requestUrl: "job/addBookmark",
                    params: {
                    "action":3,
                    "jobData":jobdata
                    },
            });
            if(addTobookMark){
                this.jobDetailsWithClientData.jobDetails.action = 3;
                this.$toastr.s('Post Saved Successfull');
            }else{
                this.$toastr.e('Post Save Unsuccessfull');
            }
        },
        // this fun is for to get similar job data
        async getsimilarJobData(){
            var similarjobs = await this.genericAPIPOSTRequest({
                    requestUrl: "getJobsList",
                    params: {
                    "user_id": this.user_id,
                    "search": {"category_id":this.jobDetails.quality_inspector_id}
                    },
            });
            if (similarjobs.length > 0 ) {
                this.similarJobsData = similarjobs;
                this.similarJobsData.filter((jobdaata,id)=>{
                if(jobdaata.jobs_id == this.jobDetails.jobs_id){
                    this.similarJobsData.splice(id,1);
                }
                })
            }
        },
        async getJobActivities(){
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "getJobsActivity",
                params: {
                "job_id": [this.jobId],
                "user_id": this.user_id
                },
            });
            if(res){
                this.jobActivities = res[0];
            }
        },
        async getPostdetails(_i){
            var getPostedData = await this.genericAPIPOSTRequest({
                requestUrl: "getJobDetails_new",
                params: {
                    'poid':_i,
                    'user_id':this.user_id,
                },
            });
            if (getPostedData.length > 0 ) {
                this.jobDetails = getPostedData[0];
                this.jobId = this.jobDetails.jobs_id
                var jobApplicationStatus = await this.genericAPIPOSTRequest({
                    requestUrl: "getJobApplicantStatus",
                    params: {
                        "jobsid" : this.jobId,
                        "user_id": this.user_id
                    },
                });
                if(jobApplicationStatus){
                    this.jobApplicationStatus =  jobApplicationStatus;
                }
                var clientDetailsapi = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getUserDetails",
                    params: {
                        "user_id":'ASS'+this.$route.params.uid,
                    },
                }); 
                if(clientDetailsapi){
                    this.userDetails =  clientDetailsapi;
                }      
                this.getJobActivities(); 
                this.getsimilarJobData();
                var clientRatingsapi = await this.genericAPIPOSTRequest({
                    requestUrl: "getUserReviewCal",
                    params: {
                        "user_id": this.user_id
                    },
                });

                if(clientRatingsapi){ 
                    this.ratingsData =  clientRatingsapi.rating;
                }  
            }else{
                this.$router.push({name: "Login",path: "/login"}).catch(()=>{});
            }
        },
        // this fun is for copy url
        copyClipboad(){
          this.$refs.clone.focus();
          document.execCommand('copy');
        },
         //this fun is for to get data onload
        async getDataOnload(){
        var jobDetailsData = await this.genericAPIPOSTRequest({
                requestUrl  : "getJobDetail",
                params      : {
                'jobsid'      :[this.job_id],
                },
        });
        if (jobDetailsData.length > 0 ) {
            
            this.jobDetailsWithClientData.jobDetails = jobDetailsData[0];      
            this.jobLink= window.location.origin+'/'+this.$store.state.accountType+'/job-details?jobDetailsWithClientData='+this.jobDetailsWithClientData.jobDetails.jobs_id;
            // this function is for get job Application Status
            var getJobApplicantStatusData = await this.genericAPIPOSTRequest({
            requestUrl    : "getJobApplicantStatus",
            params        : {
                "jobsid"        : this.job_id,
                "user_id"       : this.jobDetailsWithClientData.jobDetails.user_id
            },
            });
            if(getJobApplicantStatusData){
            this.jobDetailsWithClientData.jobApplicationStatus =  getJobApplicantStatusData;
            }
            // this function is for get Client jobs data
            var getUserPostedJobData = await this.genericAPIPOSTRequest({
            requestUrl    : "getUserPostedJob",
            params        : {
                "filter": "",
                "search": {},
                "user_id": this.jobDetailsWithClientData.jobDetails.user_id
            },
            });
            if(getUserPostedJobData){
            
            this.jobDetailsWithClientData.clientJobsData =  getUserPostedJobData;
            this.jobDetailsWithClientData.clientJobsData.filter((jobdaata,id)=>{
                if(jobdaata.jobs_id == this.job_id){
                this.jobDetailsWithClientData.clientJobsData.splice(id,1);
                }
            })
            }

            // this function is for get Client details
            var getUserDetailsData = await this.genericAPIPOSTRequest({
            requestUrl    : "/user/getUserDetails",
            params        : {
                "user_id": 'ASS'+this.encodedid(this.jobDetailsWithClientData.jobDetails.user_id)
            },
            });
            if(getUserDetailsData){
            this.jobDetailsWithClientData.clientFullDetails =  getUserDetailsData;
            }

            // // this function is for get client rating
            // var getUserReviewCalData = await this.genericAPIPOSTRequest({
            // requestUrl    : "/getUserReviewCal",
            // params        : {
            //     "user_id": this.jobDetailsWithClientData.jobDetails.user_id
            // },
            // });
            // if(getUserReviewCalData){
            // this.jobDetailsWithClientData.clientrating =  getUserReviewCalData;
            // }

            // // this function is for get client Jobs history
            // var getUserPostedJobData4 = await this.genericAPIPOSTRequest({
            // requestUrl    : "getClientContractList",
            // params        : {
            //     "filter"        : 6,
            //     "user"          : 'client',
            //     "user_id"       : this.jobDetailsWithClientData.jobDetails.user_id
            // },
            // });
            // if(getUserPostedJobData4){
            // this.jobDetailsWithClientData.clientJobshistoryData =  getUserPostedJobData4;
            // }
        }
        },
      },
      mounted() {
        this.getDataOnload();
        window.scrollTo(0, 0);
        if(this.$route.params.pid != undefined && this.$route.params.uid != undefined){
          this.user_id  = this.decodeId(this.$route.params.uid);
          this.getPostdetails(this.$route.params.pid);
        }else{
            this.$router.push({name: "Login",path: "/login"}).catch(()=>{});
        }
      }
    }
  </script>
  
  