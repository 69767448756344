<template>
    <div deck v-if="pageType =='profile' || (pageType =='public-profile' && projectData.length > 0)">
        <!-- this is model for to add project -->
        <b-modal centered no-close-on-backdrop hide-footer id="add-project-modal" size="lg" :title="(isEdit)?'Update Project':'Add Project'">
            <div class="text-left">
                <form @submit.prevent="addupdateProjects()">

                    <b-form-group class="mb-2">
                        <label class="fz-18">Project title</label>
                        <b-form-input v-model="project.title" placeholder="Project title..." class="mb-4 br-14"></b-form-input>
                        <div class="error" v-if="projectTitleErrors[0]">{{projectTitleErrors[0]}}</div>

                    </b-form-group>

                    <b-form-group class="mb-2">
                        <label class="fz-18">Project overview</label>
                        <b-form-textarea
                            v-model="project.description"
                            placeholder="The best Project Description including below elements
                            1. Your client requirement 
                            2. Details about your contribution for the project
                            3. Overview of project success 
                            4. Issues solved during the project duration "
                            rows="6"
                            max-rows="10"
                             class="mb-4 br-20"
                        ></b-form-textarea>
                        <div class="error" v-if="projectDescriptionErrors[0]">{{projectDescriptionErrors[0]}}</div>

                    </b-form-group>

                    <b-form-group class="mb-3">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12" class="py-0" v-if="!isEdit">
                                <label class="fz-18">Cover image</label>
                                <form class="mb-4">
                                    <div class="form-group">
                                        <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
                                        <div class="border p-2 preview-image">
                                            <p class="text mb-0" v-if="!preview" @click="chooseFiles()">Upload Image Here</p>
                                            <template v-if="preview">
                                                <img :src="preview" class="img-fluid" />
                                            </template>
                                        </div>
                                        
                                    </div>
                                    
                                    <a href="javascript:void(0);" class="text-theme fz-12 mr-2" v-if="preview" @click="reset() ">Cancel</a>
                                    <a href="javascript:void(0);" class="text-theme fz-12 mr-2" v-if="preview" @click="chooseFiles()">Change</a>
                                    
                                    <div class="error" v-if="projectcoverimgErrors[0]">{{projectcoverimgErrors[0]}}</div>
                                </form>
                            </b-col>
                            <b-col xl="12" lg="12" sm="12" class="py-0" v-else>
                                <label class="fz-18">Cover image</label>
                                <form>
                                    <div class="form-group" v-if="project.cover_image == ''">
                                        <input type="file" accept="image/*" @change="previewImage" class="form-control-file"  id="my-file">
                                        <div class="border p-2 preview-image">
                                            <p class="text mb-0" v-if="!preview">Upload Image Here</p>
                                            <template v-if="preview">
                                                <img :src="preview" class="img-fluid" />
                                            </template>
                                        </div>
                                        
                                    </div>
                                    <div class="form-group" v-else>
                                        <input type="file" accept="image/*" @change="previewImage" class="form-control-file" style="display:none;" id="my-file">

                                        <div class="border p-2 preview-image">
                                            <template>
                                                <img :src="project.cover_image" class="img-fluid" />
                                            </template>
                                        </div>
                                        
                                    </div>
                                    <span v-if="project.cover_image == ''">
                                        <a href="javascript:void(0);" class="text-theme fz-12 mr-2" v-if="preview" @click="reset() ">Cancel</a>
                                        <a href="javascript:void(0);" class="text-theme fz-12 mr-2" v-if="preview" @click="chooseFiles()">Change</a>
                                    </span>
                                    <span v-else>
                                        <a href="javascript:void(0);" class="text-theme fz-12 mr-2" @click="resetNew();">Change</a>
                                    </span>
                                    
                                    
                                    <div class="error" v-if="projectcoverimgErrors[0]">{{projectcoverimgErrors[0]}}</div>
                                    
                                </form>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group class="mb-2">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12" class="py-0">
                                <label class="fz-18">Project Files</label><br>
                                <form class="project-file-wrap">
                                    <div class="form-group">
                                        <input type="file" accept="image/*" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my-file-n">
                                        <p class="upload-multiple-files">Upload Image Here</p>
                                        <div class="p-2 preview-image-n"></div>
                                    </div>
                                    <template v-if="project.project_file">
                                        <template v-for="(items, ind) in project.project_file"> 
                                            <div  :key="ind" v-if="items.status <= 1" class="text-center pos-rel project-file-wrap">
                                                <img :src="items.image" class="img-fluid" style="width:100%;height:450px;margin: 0 auto;" />
                                                <h5>{{items.title}}</h5>
                                                <b-button class="btn btn-theme btn-remove btn-sm" @click="removeProjectImg('project',ind)"><i class="fa fa-close"></i></b-button>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-if="preview_list.length > 0">
                                        <div v-for="(item, index) in preview_list" :key="item.key" class="text-center pos-rel project-file-wrap">
                                            <img :src="item.image" class="img-fluid" style="width:100%;height:450px;margin: 0 auto;" />
                                            <b-form-input v-model="preview_list[index].title" class="mt-1 mb-4" placeholder="Enter a caption"></b-form-input>
                                            <b-button class="btn btn-theme btn-remove btn-sm" @click="removeProjectImg('preview',index)"><i class="fa fa-close"></i></b-button>
                                        </div>
                                    </template>
                                    <div class="error" v-if="projectfileErrors[0]">{{projectfileErrors[0]}}</div>
                                </form>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <div class="w-100 text-right">
                        <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('add-project-modal');$v.$reset();closemodel()" >Cancel</a>
                        <b-button type="submit" size="lg" class="btn btn-theme">
                            Save
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal>
        <div class="d-flex align-items-center">
            <h4 class="mb-2 text-black fz-32 fw-600">Portfolio</h4>
            <div class="ml-auto">
                <a href="javascript:void(0);" class="text-theme"  @click="$v.$reset()" v-b-modal.add-project-modal  v-if="pageType == 'profile'" >
                    <img src="web/add_circle.png" class="img-fluid">
                </a>
                
            </div>
        </div>
        <hr>
        <b-row>
            <template v-for="(pro,index) in projectData">
                <b-col xl="6" lg="6" md="6" sm="12" :key="'cat'+index" v-if="!readmore && index < 2">
                    <div class="img-container pos-rel">
                        <img :src="pro.cover_image" v-if="pageType=='profile'" class="img-fluid w-100">
                        <img :src="pro.cover_image" v-else v-b-modal.project-modal @click="protfolioData = pro" class="img-fluid w-100">
                        <button class="mr-2 btn btn-outline-theme edit-btn"  @click="editproject(pro);isEdit=true" v-if="pageType=='profile'"><i class="fa fa-pencil" ></i></button>
                        <button class="btn btn-theme trash-btn"  @click="removeProId = pro.project_id" v-b-modal.project-confirm-modal v-if="pageType=='profile'" ><i class="fa fa-trash"></i></button>
                    </div>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12" :key="'cat'+index" v-if="readmore">
                    <div class="img-container pos-rel">
                        <img :src="pro.cover_image" v-if="pageType=='profile'" class="img-fluid w-100">
                        <img :src="pro.cover_image" v-else v-b-modal.project-modal @click="protfolioData = pro" class="img-fluid w-100">
                        <button class="mr-2 btn btn-outline-theme edit-btn"  @click="editproject(pro);isEdit=true" v-if="pageType=='profile'"><i class="fa fa-pencil" ></i></button>
                        <button class="btn btn-theme trash-btn"  @click="removeProId = pro.project_id" v-b-modal.project-confirm-modal v-if="pageType=='profile'" ><i class="fa fa-trash"></i></button>
                    </div>
                </b-col>
            </template>
        </b-row>
        <b-row>
            <b-col xl="12" lg="12" md="12" sm="12" class="text-center" v-if="projectData.length > 2">
                <b-button class="btn btn-outline-theme" @click="readmore = !readmore" >{{ (readmore==true) ? 'View less portfolio' : 'View More portfolio'}}</b-button>
            </b-col>
        </b-row>

        <!-- this is model for to add project -->
        <b-modal centered no-close-on-backdrop hide-footer id="project-modal" size="lg" hide-header>
            <div class="text-left p-4">
                <div class="d-flex align-items-center">
                    <h5>{{ protfolioData.title }}</h5>
                    <div class="ml-auto">
                        <a href="#" class="text-black">
                            <img src="../../../public/web/close.png" class="img-fluid" @click="$bvModal.hide('project-modal');$v.$reset();closemodel()">
                        </a>
                    </div>                
                </div>
                <vue-read-more-smooth :lines="3" class="left-part">
                    <p>{{protfolioData.description}}</p>
                </vue-read-more-smooth>
                <hr />

                <div class="view-images-folder">
                    <div class="project-file-wrap">
                        <img :src="protfolioData.cover_image" class="img-fluid">
                    </div>
                </div>

                <div class="view-images-folder project-file-wrap">
                    <template v-for="(img,index) in protfolioData.project_file">
                        <div :key="index" v-if="img.status == 0" class="">
                            <img :src="img.image" class="img-fluid">
                            <p class="caption">{{ img.title }}</p>
                        </div>
                    </template>
                </div>

                <!-- <div class="w-100 text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('project-modal');$v.$reset();closemodel()" >Close</a>
                </div> -->
            </div>
        </b-modal>
        
        <!-- model for confirm reject -->
        <b-modal id="project-confirm-modal" title="Remove Service" centered hide-footer size="md" no-close-on-backdrop> 
            <template>
            <div class="p-5 text-center m-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <h5 class="mt-4">Are you sure, Want to Remove this Project?</h5>
            </div>
            <hr>
            <div>
                <div class="text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('project-confirm-modal')">Cancel</a>
                    <b-button type="button" class="btn btn-theme" @click="removeProject(removeServiceId)">Confirm</b-button>
                </div>
            </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import { mapActions } from "vuex";
    import VueReadMoreSmooth from "vue-read-more-smooth";
    export default {
        mixins: [validationMixin],
        props: ["pageName","pageType","userId"],
        validations: {
            project:{
                title:{ required },
                description:{ required },
                cover_image:{ required },
                project_file:{ required },
            },
        },
        components: {
            VueReadMoreSmooth
        },
        data() {
            return {
                project:{
                    title:'',
                    description:'',
                    cover_image:'',
                    project_file:[],
                },
                preview: null,
                image: null,
                preview_list: [],
                image_list: [],
                projectData:[],
                removeProId:'',
                isEdit:false,
                readmore:false,
                protfolioData :'',
            };
        },
        computed: {
            projectTitleErrors(){
                const errors = []
                if (!this.$v.project.title.$dirty) return errors 
                !this.$v.project.title.required && errors.push('Title is required')
                return errors
            },
            projectDescriptionErrors(){
                const errors = []
                if (!this.$v.project.description.$dirty) return errors 
                !this.$v.project.description.required && errors.push('Overview is required')
                return errors
            },
            projectcoverimgErrors(){
                const errors = []
                if (!this.$v.project.cover_image.$dirty) return errors 
                !this.$v.project.cover_image.required && errors.push('Cover Image is required')
                return errors
            },
            projectfileErrors(){
                const errors = []
                if (!this.$v.project.project_file.$dirty) return errors 
                !this.$v.project.project_file.required && errors.push('Project File is required')
                return errors
            },
            
        },
        async mounted() { 
            this.getProjectsData();
        },
        methods: {
            ...mapActions(["genericAPIPOSTRequest"]),
            // this fun is for to remove project image
            removeProjectImg(_t,_i){
                console.log(_t,_i)
                if(_t=='project'){
                    this.project.project_file[_i].status = 2;
                }else if(_t == 'preview'){
                    this.preview_list.splice(_i, 1); 
                }
            },
            // this fun is for to close model
            closemodel(){
                this.project = {
                    title:'',
                    description:'',
                    cover_image:'',
                    project_file:[],
                };
            },
            // this fun is for to choose file
            chooseFiles() {
                document.getElementById("my-file").click()
            },
            // this fun is for to get projects data
            async getProjectsData(){
                try {
                    this.projectData =  await this.genericAPIPOSTRequest({
                            requestUrl: "getAgencyProject",
                            params: {
                                "user_id": (this.pageType == 'public-profile') ? this.userId :'',
                            },
                    });     
                } catch (error) {
                    this.projectData = [];
                }
            },
            // this fun is for to preview over 
            previewImage(event) {
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        this.preview = e.target.result;
                    }
                    this.image=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            // this fun is for to preview the multiple image upload
            previewMultiImage: function(event) {

                var input = event.target;
                var count = input.files.length;
                console.log('event',count,input.files)
                // var index = 0;
                if (input.files) {
                    for (let index = 0; index < count; index++) {
                        // var reader = new FileReader();
                        // reader.onload = (e) => {
                        //     if(this.preview_list.length > 0){
                        //         this.preview_list.push({image:e.target.result,title:''});
                        //     }else{
                        //         this.preview_list =[{image:e.target.result,title:''}];
                        //     }
                        // }
                        var reader = new FileReader();
                        reader.onload = (e) => {
                            // Using a unique key (e.g., Date.now() + index) for each image.
                            const newImage = { 
                                image: e.target.result, 
                                title: '', 
                                key: Date.now() + index // Using current timestamp and index to ensure uniqueness
                            };

                            // Adding to preview_list with unique keys
                            this.preview_list.push(newImage);
                        }
                        this.image_list.push(input.files[index]);
                        reader.readAsDataURL(input.files[index]);
                        
                    }
                    // while(count --) {
                    //     var reader = new FileReader();
                    //     reader.onload = (e) => {
                    //         this.preview_list.push({image:e.target.result,title:''});
                    //     }
                    //     this.image_list.push(input.files[index]);
                    //     reader.readAsDataURL(input.files[index]);
                    //     index ++;
                    // }
                }
            },
            //this fun is for to reset the cover image
            reset() {
                this.image = null;
                this.preview = null;
                this.project.cover_image = '';
            },
            // this fun is for to reset the cover image when edit the project
            resetNew() {
                this.image = null;
                this.preview = null;
                this.project.cover_image = '';
                setTimeout(() => {
                    this.chooseFiles();
                }, 1000);
            },
            /// this fun is for to remove project
            async removeProject(){
                await this.genericAPIPOSTRequest({
                    requestUrl: "UpdateProject",
                    params: { 
                        project_id:this.removeProId,
                        status:'1', 
                    },
                }); 
                
                this.$bvModal.hide('project-confirm-modal');
                this.getProjectsData();
                this.removeProId='';
            },
            //this fun is for to edit project
            editproject(data){
                this.project = data;
                this.$bvModal.show('add-project-modal'); 
                // this.consolelog('project',this.project);
            },
            // this fun is for to add update projects
            async addupdateProjects() {
                if(this.isEdit == false){
                    this.project.cover_image    = this.preview;
                    this.project.project_file   = this.preview_list;
                    this.$v.project.$touch(); 
                    if (!this.$v.project.$invalid ) { 
                        var res = await this.genericAPIPOSTRequest({
                            requestUrl: "addProject",
                            params: { 
                                user_type       : this.$store.state.accountType,
                                title           : this.project.title,
                                description     : this.project.description,
                                cover_image     : {'image':this.project.cover_image},
                                project_file    : this.project.project_file,
                                created_by      : {data:this.getLoginUserIdRequest()},
                            },
                        });  
                        if(res){
                            this.$bvModal.hide('add-project-modal'); 
                            this.getProjectsData();
                            this.$toastr.s('Project Added Successfully');
                            this.project={
                                title:'',
                                description:'',
                                cover_image:'',
                                project_file:[],
                            }
                            this.image = '';
                            this.preview = '';
                            this.preview_list= [];
                            this.image_list= [];
                        }else{
                            this.$toastr.e('Project Not Added');
                        }
                    }
                }else{
                    this.project.cover_image = (this.project.cover_image!='') ? this.project.cover_image : this.preview;
                    if(this.preview_list.length > 0){
                        this.preview_list.filter(data=>{
                            this.project.project_file.push({
                                image:data.image,
                                title:data.title,
                                status:1,
                            })
                        })
                    }
                    this.$v.project.$touch(); 
                    if (!this.$v.project.$invalid) { 
                        var updatedata              = {};
                        updatedata.project_id       = this.project.project_id;
                        updatedata.title            = this.project.title;
                        updatedata.description      = this.project.description;
                        if(this.project.cover_image!=''){
                            updatedata.cover_image  = {'image':this.project.cover_image};
                        }
                        updatedata.project_file     = this.project.project_file;

                        var ress = await this.genericAPIPOSTRequest({
                            requestUrl: "UpdateProject",
                            params: updatedata,
                        });  
                        if(ress){
                            this.$bvModal.hide('add-project-modal'); 
                            this.getProjectsData();
                            this.$toastr.s('Project Updated Successfully');
                            this.isEdit=false;
                            this.project={
                                title:'',
                                description:'',
                                cover_image:'',
                                project_file:[],
                            }
                            this.image = '';
                            this.preview = '';
                            this.preview_list= [];
                            this.image_list= [];
                        }else{
                            this.$toastr.e('Project Not updated');
                        }
                    }
                }
            },
        },
    };
</script>
<style scoped>
.border-radius-custom {border-radius:0 0 6px 6px!important;}
.border-radius-custom .card-body{border-radius:0 0 6px 6px!important;}
</style>