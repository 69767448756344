<template>
    <div class="relative w-full h-auto overflow-auto bg-white">
          <TheHomeSiteHeader />
        <div class="col-md-12 col-12">
            <div class="w-full mx-auto  xl:mx-auto xl:max-w-7xl mb-10">
                <div class="container master-tag">
                    <div id="w-node-_412574db-0221-58f3-f7ef-37cfdc222117-e5ae7e41" class="column">
                        <div class="contact">
                            <h1 class="margin-5">Contact Us</h1>

                            <h2 class="h4-style qapin-neue margin-30">Reach out anytime</h2>
                        </div>

                        <div class="row inner-addmission">
                            <div class="col-md-6">
                                <a href="#" class="box d-block">
                                    <div class="d-md-flex align-items-center">
                                        <div>
                                            <h5 class="fz-18 fw-600 text-white mb-0">Customer Support </h5>
                                            <h5 class="fz-16 fw-600  mb-0 lg-mt-5 xl-pt-5 pointer-link-right"> Visit Help Center<i
                                                    class="fa fa-arrow-right"></i></h5>
                                        </div>
                                        <div class="ml-auto">
                                            <img src="web/icon/customer-service.png"
                                                class="img-fluid ">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6">
                                
                                <a href="#" class="box d-block third-box">
                                    <div class="d-md-flex align-items-center">
                                        <div>
                                            <h5 class="fz-18 fw-600 text-white mb-0">Enterprise Solutions</h5>
                                            <h5 class="fz-16 fw-600  mb-0 lg-mt-5 xl-pt-5 pointer-link-right"> Visit Help Center<i
                                                    class="fa fa-arrow-right"></i></h5>
                                        </div>
                                        <div class="ml-auto">
                                            <img src="web/icon/problem-solving.png"
                                                class="img-fluid ">
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-md-6">
                                <a href="#" class="box d-block">
                                    <div class="d-md-flex align-items-center">
                                        <div>
                                            <h5 class="fz-18 fw-600 text-white mb-0">Press Inquiries </h5>
                                            <h5 class="fz-16 fw-600  mb-0 lg-mt-5 xl-pt-5  pointer-link-right"> Visit Help Center<i
                                                    class="fa fa-arrow-right"></i></h5>
                                        </div>
                                        <div class="ml-auto">
                                            <img src="web/icon/press.png"
                                                class="img-fluid ">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6">
                                <a href="#" class="box d-block">
                                    <div class="d-md-flex align-items-center">
                                        <div>
                                            <h5 class="fz-18 fw-600 text-white mb-0">Partnerships</h5>
                                            <h5 class="fz-16 fw-600  mb-0 lg-mt-5 xl-pt-5 pointer-link-right"> Visit Help Center<i
                                                    class="fa fa-arrow-right"></i></h5>

                                        </div>
                                        <div class="ml-auto">
                                            <img src="web/icon/partnership.png"
                                                class="img-fluid ">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="contact mt-4">
                            <h2 class="h4-style qapin-neue margin-30">Our Offices</h2>
                        </div>
                    </div>

                    <div id="w-node-ab7998da-36c8-c604-8cd9-e8038c0c297e-1bae7e72" class="map-card"><img
                            src="http://assets-global.website-files.com/603fea6471d9d8559d077603/6059ee60cf45f5094ecc1aaa_map.png"
                            loading="lazy" alt="Map" class="map-image">
                        <div class="map-column">
                            <div class="h5-style text-white">Global HQ</div>
                            <p class="no-margin text-white">9655 Granite Ridge<br>Dr Suite 200, CA 92123<br>San Diego<br>United States</p>
                        </div>
                    </div>
                      <div id="w-node-ab7998da-36c8-c604-8cd9-e8038c0c297e-1bae7e72" class="map-card"><img
                            src="http://assets-global.website-files.com/603fea6471d9d8559d077603/6059ee60cf45f5094ecc1aaa_map.png"
                            loading="lazy" alt="Map" class="map-image">
                        <div class="map-column">
                            <div class="h5-style text-white">China Office </div>
                            <p class="no-margin text-white">No. 418, Zhong An Road,<br>Linhu Town,<br>Wuzhong District,<br>Suzhou, China-215105</p>
                        </div>
                    </div>
                      <div id="w-node-ab7998da-36c8-c604-8cd9-e8038c0c297e-1bae7e72" class="map-card"><img
                            src="http://assets-global.website-files.com/603fea6471d9d8559d077603/6059ee60cf45f5094ecc1aaa_map.png"
                            loading="lazy" alt="Map" class="map-image">
                        <div class="map-column">
                            <div class="h5-style text-white">India Office</div>
                            <p class="no-margin text-white">Balaji Coloy
                            <br>Tirupati<br>Andhra Pradesh<br>India- 517502</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
         <!-- <TheHomeSiteFooter class="" v-if="!isAuth" /> -->
    </div>

    <!-- text-muted -->

</template>

<script>
import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
// import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
        //  TheHomeSiteFooter,
       TheHomeSiteHeader
    },
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped lang="css">
.agency-category {
    background-color: white;
}


h1 {
    margin: 0 0 15px;
    color: #F2613C;
    font-size: 56px;
    line-height: 1em;
    font-weight: 400;
    letter-spacing: -.035em;
    padding-bottom: 15px;
}

.qapin-neue {
    font-family: Neuemontreal, sans-serif;
    color: #001e00;
    font-weight: 500;
    letter-spacing: -.035em;
}

.inner-addmission {
    top: 0;

}

.margin-30 {
    margin-bottom: 30px;
}


.box {
    transition: box-shadow .3s;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
    background:  #F2613C;
    float: left;
    padding: 30px 10px;
}

.fa {
    position: relative;
    z-index: 2;
    width: 11px;
    height: 9px;
    margin-left: 5px;
}

.contact-column {
    margin-bottom: 10px;
    font-family: Neuemontreal, sans-serif;
    color: #001e00;
    font-size: 22px;
    line-height: 1.1em;

    letter-spacing: -.02em;
    display: -webkit-box;

    display: flex;


}
.h5-style {
    margin-bottom: 10px;
    font-family: Neuemontreal,sans-serif;
    color: #001e00;
    font-size: 22px;
    line-height: 1.1em;
    font-weight: 500;
    letter-spacing: -.02em;
}
.pointer-link-right {
    font-family: Neuemontreal, sans-serif;
    color: #ffffff;
    font-weight: 500;
    text-decoration: underline;

}

.box:hover {
    box-shadow: 0 0 30px rgba(179, 70, 70, 0.593);
}

@media (min-width: 1280px) {

    .xl\:max-w-7xl {
        max-width: 65rem;
    }
}

h5 {
    padding: 10px;
    color: black;
    font-weight: 500;
}

img {
    height: 100px;
    width: 100px;
}

.contact {
    padding-left: 30px;
}

.map-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border-radius: 16px;
    background-color:  #F2613C;
    margin: 13px;
}

.map-image {
    width: 40%;
    height: 0%;
    min-height: 168px;
}

.map-column {
    display: flex;
    width: 50%;
    padding: 15px 15px 15px 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
</style>
