<template>
  <div @click="navigateAndClose()">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppDropdownItem',
  inject: ['sharedState'], // Inject sharedState from AppheaderDropdown
   
  methods: {
    navigateAndClose() { 
      this.sharedState.active = false; // Close dropdown by updating sharedState
    },
  },
};
</script>
