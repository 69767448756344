<template>
<b-container>
    <b-card-text>
        <b-card-group deck>
            <b-card class="py-4 px-4 bg-white br-20 mt-100">
                <div class="d-flex align-items-center">
                    <h3 class="fw-600 text-black mr-2 mb-0">Agency Roaster</h3>
                    <!-- <div class="ml-auto">
                        <input id="bd-search-input" type="search" placeholder="Search..." autocomplete="off" aria-label="Search docs" class="form-control ds-input" spellcheck="false" role="combobox" aria-autocomplete="list" aria-expanded="false" aria-owns="algolia-autocomplete-listbox-0" dir="auto" style="position: relative; vertical-align: top;">
                    </div> -->
                </div>
                <hr>
                <b-row>
                    <b-col xl="12" lg="12" sm="12">
                        <div class="table-responsive">
                            <table class="w-100">
                                <tbody>
                                    <tr v-for="(user, index) in userData" :key="index">
                                        <td class="profile-wrap">
                                            <div class="img-wrapper pos-rel">
                                                <div class="profile pos-rel">
                                                    <span class="online" :class="(checkUserOnlineOrOffline(user) == true) ? 'bg-success' : ''"></span>
                                                    <img :src="$store.state.baseUrlImg+'/profile_image/'+user.profile_picture_path" alt="Profile Pic" class="rounded-md w-14 h-14">
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="text-theme"><b>{{ shortName(user.first_name,user.last_name) }}</b></span><br>
                                            <span><b>{{ encodedid(user.user_id) }}</b></span>
                                        </td>
                                        <td class="text-theme">
                                            <span>{{ 'Contracts' }}</span><br>
                                            <span class="cursor-pointer" v-on:click="$router.push({name:'agencymyProposals',path:'/agency/my-proposals',query:{'uid':encodedid(user.user_id)}}).catch(()=>{})"><b>{{ 'Proposals' }}</b></span>
                                        </td>
                                        <td>
                                            <span>Hourly Rate</span><br>
                                            <span><b> ${{ user.hourly_rate }}</b></span>
                                        </td>
                                        <td>
                                            <span>Profile Privacy</span><br>
                                            <span><b>{{ (user.profile_visibility==0)?'Public':(user.profile_visibility==1)?'Qapin User':'Private' }}</b></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-card-group>
    </b-card-text>
</b-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
    // mixins: [validationMixin],
    validations: { 
        
    },
    data() {
        return {
            userdetailApidata   : this.getCurrentUserIdRequest(),
            userData            : [],
        };
    },
    computed: {
    }, 
    mounted() {
        this.getactiveMembers();
    },
    methods: {
        ...mapActions(["genericAPIPOSTRequest"]),
        // this fun is for to get active members
        async getactiveMembers(){
            var res = await this.genericAPIPOSTRequest({
            requestUrl      : "getsendUserInvitation",
            params          : {
                status          : 1,
                data            : this.userdetailApidata,
                type            : this.$store.state.accountType,
                subType         : 'agency roaster'
            },
            });
            if (res) {
                this.userData = res; 
            }
        },
    }
};
</script>