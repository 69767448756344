<template>
    <b-container >
      <div 
      v-infinite-scroll="getTransactionHistory"
      :infinite-scroll-disabled="busy"
      infinite-scroll-distance="5" class="transaction-history-wrapper">
        <b-row class="mt-100 posting-wrap" >
          <b-col xl="12" lg="12" sm="12">
            <div class="d-md-flex align-items-center mb-3">
              <h1 class="mb-0 fw-500 text-black page-title fz-2_5"><b>Transaction History</b></h1>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col xl="3" lg="3" sm="12">
            <h4 class="mb-0 h4">Balance: <span class="text-theme">${{parseFloat(availableBalance).toFixed(2)}}</span></h4>
          </b-col>
          <!-- <b-col xl="3" lg="3" sm="12">
            <h4 class="mb-0 h4">Escrow balance: <span class="text-theme cursor-pointer" @click="$router.push('/client/escrow-data').catch(()=>{})">{{escrow}}</span></h4>
            <small class="text-muted">Not included in balance</small>
          </b-col> -->
        </b-row>

        <b-row>
          <b-col xl="12" lg="12" sm="12"> 
            <b-card-group deck>
              <b-card title-class="text-theme font-14" class="py-1 px-1 mt-0" >
                <div class="d-md-flex align-items-center justify-content-space-between filter-card">
                  <div>
                    <b-form-group>
                      <label class="fz-16">Statement period</label><br>
                      <!-- https://innologica.github.io/vue2-daterange-picker/#installation -->
                      <!-- https://innologic. 4a.github.io/vue2-daterange-picker/advanced/#slots-demo -->
                      <!-- https://unpkg.com/browse/vue2-daterange-picker@0.2.6/README.md -->
                      <date-range-picker v-model="dateRange" :options="dateOptions" class="date-picker" @update="filterDate($event)" />
                    </b-form-group>
                  </div>
                  <div>
                    <b-form-group>
                      <label class="fz-16">Transaction category</label>
                      <b-form-select 
                        v-model="filtersValue.transactionCategory" 
                        :options="options"
                        v-on:change="filterData"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div>
                    <b-form-group>
                      <label class="fz-16">Freelancer</label>
                      <b-form-select 
                      v-model="filtersValue.freelancer_id" 
                      :options="freelancerList"
                      value-field="user_id"
                      text-field="fullName"
                      v-on:change="filterData"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div>
                    <b-form-group>
                      <label class="fz-16">Client</label>
                      <b-form-select v-model="filtersValue.client_id" 
                        :options="clientList"
                        value-field="user_id"
                        text-field="company_name"
                        v-on:change="filterData"
                      ></b-form-select>
                      <!-- <b-card-body class="select-agency">
                        <div class="input-group search-bar mt-4">
                            <div class="select-agency-class">
                            <input type="text" class="form-control border-none" v-model="searchAgency" placeholder="Search for Language" aria-describedby="basic-addon2">
                            <b-form-group class="ol-filter">
                                <b-form-checkbox
                                v-for="(agency,id) in clientListData"
                                :key="id"
                                :value="agency.user_id"
                                v-model="filtersValue.agency_id"
                                >
                                {{ agency.company_name }}
                                </b-form-checkbox>
                            </b-form-group>
                            </div>
                        </div>
                        </b-card-body> -->
                    </b-form-group>
                  </div>
                  
                  <div>
                    <label class="mb-4"></label>
                    <div class="d-flex align-items-center">
                      <button class="btn btn-outline-theme mr-2" >
                        <i class="fa fa-file" aria-hidden="true"></i>
                      </button>
                      <button class="btn btn-theme">
                        Download invoices
                      </button>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="12" lg="12" sm="12">    
            <b-card-group deck>
              <b-card title-class="text-theme font-14" class="py-2 px-2 mt-0 border-bottom" >
                <div class="border-bottom pb-1 pt-3">
                  <div class="table-responsive">
                    <span v-if="historyData.length > 0">
                        <b-table bordered :table-variant="'none'" :items="historyData" :fields="fields">
                          <template #cell(amount_balance)="data">
                            <span @click="transactionDetails = data">
                                {{ '('+data.value+')' }}
                                <br>
                                {{ data.item.releaseStatus }}
                            </span>
                          </template>
                          <template #cell(ref_id)="data">
                            <span class="cursor-pointer text-ul" @click="openHistoryModal(data)">{{ data.value }}</span>
                          </template>
                        </b-table>
                    </span>
                    <div class="posted-job-wrapper border-bottom" v-else>
                        <div>
                            <div class="text-center py-8 px-4" >
                            <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                                <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                                <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                                <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                                <g clip-path="url(#clip0_3226_22395)">
                                <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                                <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                                <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                                <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                                <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                                <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                                <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                                <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                                <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                                <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                                <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                                <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_3226_22395">
                                <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <h4 class="fz-20 fw-600 text-black">No Transaction History Found</h4>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </b-card>
              <b-modal id="modal-history" title="BootstrapVue" hide-header centered no-close-on-backdrop>
                <h4 class="title">Transaction details</h4>

                <ul class="minus--30 transaction-list" v-if="transactionDetails !=''">
                  <li>
                    <h6>Refrence ID</h6>
                    <span>{{transactionDetails.ref_id}}</span>
                  </li>
                  <li>
                    <h6>Date</h6>
                    <span>{{transactionDetails.date}}</span>
                  </li>
                  <li>
                    <h6>Type</h6>
                    <span>{{transactionDetails.type}}</span>
                  </li>
                  <li>
                    <h6>Description</h6>
                    <span>{{transactionDetails.description}}<br><span v-if="transactionDetails.type == 'Marketplace Fee'"> {{ transactionDetails.details.amount+' USD x 5.00 % = '+transactionDetails.amount_balance_+' USD' }}</span></span>
                    
                  </li>
                  <li>
                    <h6>Amount</h6>
                    <span>{{transactionDetails.amount_balance}}</span>
                  </li>
                  <li>
                    <h6>More info</h6>
                    <span><a href="javascript:void(0);" @click="exportSingleToPDF(transactionDetails)" class="text-theme">View invoice</a></span>
                  </li>
                </ul>
                <template #modal-footer>
                  <div class="w-100 text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('modal-history')">Close</a>
                  </div>
                </template>
              </b-modal>
              <div class="text-center py-4 text-theme" v-if="loaderStatus">
                <b-spinner type="border" medium></b-spinner>
              </div>
            </b-card-group> 
          </b-col>
        </b-row>
      </div>
    </b-container>
  </template>
  <script>
    import { mapActions,mapGetters } from "vuex";
    import moment from 'moment'
    import DateRangePicker from 'vue2-daterange-picker'
    //you need to import the CSS manually
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
      components: {
        DateRangePicker 
      },
      computed: {
        ...mapGetters(['getInvoiceFromDetails']),
        clientListData(){
        // function to compare names
            function compare(a, b) {
            if (a.company_name < b.company_name) return -1;
            if (a.company_name > b.company_name) return 1;
            return 0;
            }
            if(this.clientList.length > 0){
            return this.clientList.filter(data => {
                return data.company_name.toLowerCase().includes(this.searchAgency.toLowerCase());
            }).sort(compare);
            }else{
            return [];
            }
        },
        startFromDate(){
          return (this.filtersValue.contract_start_from=='')?new Date().toISOString().substr(0, 10):this.filtersValue.contract_start_from;
        },
        endFromDate(){
          return (this.filtersValue.contract_end_from=='')?new Date().toISOString().substr(0, 10):this.filtersValue.contract_end_from;
        }
      },
      data() {
        return {
          selected                : '',
          historyData             : [],
          availableBalance        : '0',
          escrow                  : '0',
          searchAgency            : '',
          clientList              : [
            { user_id: '', company_name: 'All Clients' }
          ],
          freelancerList          : [
            { user_id: '', fullName: 'All freelancer' }
          ],
          busy                    : false,
          loaderStatus            : false,
          filtersValue            : {
            StartDate             : new Date(new Date().setFullYear(new Date().getFullYear() - 3)),
            EndDate               : new Date().toISOString().substr(0, 10),
            start_limit           : 0,
            end_limit             : 3,
            sort                  : 'DESC',
            client_id             : '',
            freelancer_id         : '',
            transactionCategory   : '',
            type                  : this.$store.state.accountType,
          },
          fields                  : [
            { key: 'date', label: 'Date', class: 'table-wid-10' },
            { key: 'type', label: 'Type', class: 'table-wid-10' },
            { key: 'description', label: 'Description', class: 'table-wid-40' },
            { key: 'freelancer', label: 'Freelancer', class: 'table-wid-10' },
            { key: 'client', label: 'Client', class: 'table-wid-10' },
            { key: 'amount_balance', label: 'Amount balance', class: 'table-wid-10' },
            { key: 'ref_id', label: 'Ref Id', class: 'table-wid-10' },
            // 'date','type','description','freelancer','client','amount_balance','ref_id'
          ],
          dateOptions: {
            timePicker: false,
            minYear: 2020,
            maxYear: +moment().format("YYYY")
            // startDate: moment().startOf('hour'),
            // endDate: moment().startOf('hour').add(32, 'hour'),
            // locale: {
            //     format: 'M/DD hh:mm A'
            // }
          },
          dateRange: { // used for v-model prop
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 3)),
            endDate: new Date().toISOString().substr(0, 10),
          },
          options: [
            { value: '', text: 'All transactions' },
            { value: 'fixed_price', text: 'Fixed Price' },
            { value: 'serviceFees', text: 'Service Fee' },
            { value: 'withdraw', text: 'Withdrawal' },
            // { value: 'd', text: 'Marketplace Fee', disabled: true }
          ],
          transactionDetails : [],
        }
      },
      methods: {
        ...mapActions(['genericAPIPOSTRequest']),
        // this fun is for to open invoice modal
        async openHistoryModal(_d){
          if(_d.item.type == "Invoice"){
            if(_d.item.client_id !='' || _d.item.client_id != null){
              var clientData = await this.genericAPIPOSTRequest({
                requestUrl: "user/getUserDetails",
                params: { 
                  'user_id'   : 'ASS'+this.encodedid(_d.item.client_id),
                  'usertype'  : "client"
                },
              });
              if (clientData) {
                _d.item.details.frombilling = clientData;
              }
            }
          }else {
            _d.item.details.frombilling = this.getInvoiceFromDetails;
          }
          this.transactionDetails = _d.item; 
          console.log('this.transactionDetails',this.transactionDetails)
          // this.$bvModal.show('modal-history')
        },
        // this fun is for to Check Available balance
        async checkBalance(){
          var response = await this.genericAPIPOSTRequest({
              requestUrl    : "availableBalance",
              params        : {
                "type"          :this.$store.state.accountType,
              },
          });
          if(response >= 0){
            this.availableBalance = response;
          }
          this.getTransactionHistory();
        },
        // this fun is for to get data on change of filter
        async filterDate(_v){
            this.historyData = [];
            this.filtersValue.start_limit = 0;
            this.filtersValue.StartDate = _v.startDate;
            this.filtersValue.EndDate = _v.endDate;
            this.busy = false;
            this.getTransactionHistory();
        },
        async filterData(){
            this.historyData = [];
            this.filtersValue.start_limit = 0;
            this.busy = false;
            this.getTransactionHistory();
        },
        // this fun for active contract
        async getTransactionHistory(){
          if(this.busy)return true;
          this.busy                   = true;
          this.loaderStatus           = true;
          var res                     = await this.genericAPIPOSTRequest({
              requestUrl: "freelancertransactionHistory",
              params                : {
                "data"    : this.getLoginUserIdRequest(),
                "search"  : this.filtersValue,
              },
          });  
          if(res!=""){
            var that                = this;
            res.response.map(function(item){ return  that.historyData.push(item)  } );
            this.filtersValue.start_limit = res.start;
            this.balance            = res.balance;
            this.escrow            = res.escrow;
            this.busy               = false;
          }
          this.loaderStatus         = false;
        },

        // this fun is for to get all agency list
        async getClient(){
            var res                     = await this.genericAPIPOSTRequest({
                requestUrl: "getAgencyClientList",
                params                : {
                    "data"    : this.getLoginUserIdRequest(),
                },
            });  
            if(res.length > 0){
                var that = this;
                res.map(function(item){ return  that.clientList.push(item)  } );
            }
        },

        // this fun is for to get all agency list
        async getFreelancer(){
            var res                     = await this.genericAPIPOSTRequest({
                requestUrl: "getWorkedFreelancerList",
                params                : {
                    "data"    : this.getLoginUserIdRequest(),
                },
            });  
            if(res.length > 0){
                var that = this;
                res.map(function(item){ return  that.freelancerList.push(item)  } );
            }
        },

        // this fun is for to download single invoice pdf
        exportSingleToPDF(_v) {
          this.$store.dispatch("updateSingleInvoiceData", _v);
          window.open(this.$store.state.frontendUrl+'singleInvoice.html');
          // window.open(this.$store.state.frontendUrl+'singleInvoice.html?id='+btoa(JSON.stringify(_v)));
        },
      },
      mounted(){
        this.checkBalance();
        this.getClient();
        this.getFreelancer();
        window.scrollTo(0, 0);
        if(this.isPermissionForClient('transactionHistory')!= true){
          history.go(-1)
        }
      }
    }
  </script>



