<template>
    <span>
        <div class="row message-head pos-rel pt-5 mt-3">
            <div class="content-preloader"  v-if="chatLoader && (pageType == 'chatpage' && roomlist.length > 0 || pageType == 'contractpage')">
                <div class="loader-wrapper">
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="typing-title" v-if="sendChat.message!=''">
                <h6 class="mb-0 pos-absolute text-muted">Typing...</h6>
            </div> -->
            <div class="row message overflow-x-hidden pos-rel" @scroll="(!chatLoader)?handleScroll($event):''" id="conversation" ref="msgContainer">
                <!-- <div class="typing-title" v-if="sendChat.message!=''">
                    <h6 class="mb-0 pos-absolute text-muted">Typing...</h6>
                </div> -->
                <span v-if="messagelist.length > 0 " >
                    <!-- {{filterSectionHeight}} -->
                    <div class="row message-body"   v-for="(msgs,index) in  messagelist " :key="index"  >
                        <div class="col-sm-12 mb-2">
                            <div class="unread-time-wrapper" >
                                <div class="pos-rel time-wrap">
                                    <span class="time pos-absolute">{{msgs.key}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="content-holder mt-2"  v-for="(msg,ind) in  msgs.value" :key="ind">
                            <div class="d-flex align-items-center" >
                                <div class="img-holder w-10">
                                    <div class="heading-avatar-icon">
                                        <img :src="msg.key.profile_picture_path">
                                        <span class="status-icon " :class="'status-'+msg.key.sended_by"></span>
                                    </div>
                                </div>
                                <div class="content-holder d-flex align-items-center">
                                    <p class="mb-0 ml-2 fw-600">{{msg.key.first_name}} {{msg.key.last_name }}</p>
                                    <span class="msg-time ml-2">{{ convertToLocalTime(msg.key.time) }}</span>
                                </div>
                            </div>
                            <div v-for="(messages,mindex) in msg.value" :key="mindex" :id="mindex" :class="(messages.type==3)?'pdf-div-css':(messages.type==2)?'chat-img-div-css':''">
                                <!-- {{'index'+ mindex}} -->
                                <div class="mb-2 pl-50"  v-if="messages.type == 1">
                                     <!-- {{ 'v-if'+messages.type }} -->
                                    <!-- {{ consolelog('messages-v-if'+mindex,messages) }} -->
                                    <div class="single-msg" id="single-msg">
                                        <span v-if="messages.type==1" class="msg-span">
                                            <!-- <read-more more-str="Read more" :text="getHtmlContent(messages.message)" link="#" less-str="Read less" :max-chars="300"></read-more> -->
                                            <div v-if="messages.is_deleted == 0"><p class="line-break-auto" v-html="getHtmlContent(messages.message)"></p></div>
                                            <div  class="mb-3" v-else><span class="bg-grey p-2 br-10 text-muted"><i>{{(messages.sended_by == $store.state.currentUserData.login_master_id)?'You removed this message':'Message Was Removed'}}</i></span></div>
                                            <!-- <span class="msg-time ml-2">{{ messages.time }}</span> -->
                                        </span>
                                        <div class="dropdown-option" v-if="messages.is_deleted == 0 && messages.type == 1 && messages.sended_by == $store.state.currentUserData.login_master_id">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret v-if="messages.sended_by == $store.state.currentUserData.login_master_id" >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);"  @click="deleteMsg(messages.chat_id,index,ind,mindex,$event)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);"  @click="updateMessage.editMessage = true; sendChat.message = messages.message; updateMessage.chatIndex = index; updateMessage.chatData = messages; updateMessage.chatParentIndex = ind; updateMessage.chatChildIndex= mindex;">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="inline-block mb-2 parent img-parent"  v-else-if="messages.type == 2">
                                    <!-- {{ 'v-else-if'+mindex+messages.type }} -->
                                    <!-- {{ messages.data }} -->
                                    <div class="msg-span file-span pos-rel chat-img-div-css mr-4" id="img-div" v-for="(documents,docIndex) in messages.data " :key="docIndex" >
                                        <!-- {{ documents }} -->
                                        <span class="msg-span p-0"> 
                                            <img :src="documents.media" width="100" height="100" @click="setSlider(messages.data),setImage(docIndex)" v-b-modal.modal-image-slider>
                                            <!-- <span class="msg-time">{{ documents.time }}</span> -->
                                        </span>
                                        <div class="dropdown-option" v-if="documents.type==2">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.login_master_id == documents.sended_by" @click="deleteFile(documents.chat_id,index,ind,mindex,docIndex)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);" v-else @click="downloadFile(documents.media_withoutbasepath)">
                                                    <i class="fa fa-download" aria-hidden="true"></i> Download
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="messages.type==3" class="parent file-parent">
                                    <div class="msg-span file-span pos-rel pdf-div-css" v-for="(documents,docIndex) in messages.data " :key="docIndex" id="file-div">
                                        <a href="javascript:void(0)" class="text-black"><i class="fa fa-file mr-4" aria-hidden="true"></i>{{ documents.message }}</a>
                                        <div class="dropdown-option" v-if="documents.type==3">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.login_master_id == documents.sended_by" @click="deleteFile(documents.chat_id,index,ind,mindex,docIndex)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);" v-else @click="downloadFile(documents.media_withoutbasepath)">
                                                    <i class="fa fa-download" aria-hidden="true"></i> Download
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span class="no-data-conversation" v-else>
                    <a href="javascript:void(0)">
                        <div class="col-sm-12 col-xl-12 sideBar-avatar text-center">
                            <div class="image-holder">
                                <img src="/web/chat-box.png" alt="Chat" class="img-fluid">
                            </div>
                            <h4 class="fz-16 fw-600 text-muted mb-0">Nothing here</h4>
                            <p class="fz-12 fw-400 text-muted">There are no chats in your feed. <a href="javascript:void(0);" class="text-theme">Create a New Chat.</a></p>
                        </div>
                    </a>
                </span>
            </div>
        </div>
        <div class="up-d-composer-attachment" v-if="preview_list.length > 0  && !chatLoader">
            <div class="up-d-file-attachment composer-attachment" v-for="(prev,pindex) in preview_list" :key="pindex">
                <div class="file-attachment">
                    <div style="display: flex;">
                        <div class="attachment-preview">
                            <a target="_blank" class="up-d-story-img loaded" style="width: 40px; height: 40px;">
                                <img :src="image_list[pindex]" v-if="prev.type == 'image/jpeg' || prev.type == 'image/jpg' || prev.type == 'image/png' || prev.type == 'image/svg' || prev.type == 'image/gif'">
                                <i class="fa fa-file mr-4" v-else aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="file-name-wrapper">
                            <span class="file-name">
                                <span class="name">
                                    {{prev.name}}
                                </span>
                            </span> 
                            <div class="file-content">
                                <span class="text-size d-flex align-items-center">
                                    {{prev.size | sizeInMb}}
                                    <span class="file-check-icon"  :id="'progress-complete_'+pindex" style="display: none;"></span>
                                </span>
                            </div>
                        </div> 
                        <span class="remove-attachment" @click="removePrev(pindex)">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img" class="remove-icon"><polygon fill-rule="evenodd" points="12.524 0 7 5.524 1.476 0 0 1.476 5.524 7 0 12.524 1.476 14 7 8.476 12.524 14 14 12.524 8.476 7 14 1.476"></polygon></svg>
                        </span>
                    </div>
                    <div class="progress progress-height" :id="'progress-parent_'+pindex" v-if="prev.successStatus != 100">
                        <div class="progress-bar" role="progressbar" :id="'progressbar_'+pindex" :style="'width:'+ prev.successStatus+'%'" :aria-valuenow="prev.successStatus" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="chat-input" v-if="getmessageparams.chat_room_id!='' && !chatLoader">
            <div class="row reply d-flex align-items-center m-0">
            <!-- <div class="row reply d-flex align-items-center m-0" v-if="messagelist.length > 0 || pageType=='sidebar' || pageType=='contractpage' "> -->
                <form @submit.prevent="submitform" class="d-flex align-items-center p-0">
                    <input  id="fileUpload"
                        style="display: none;"
                        multiple
                        hidden type="file"
                        @change="UploadFile"
                        accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.word,.pdf,.xlsx,.xls,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                    >
                    <div class="reply-emojis w-100 pos-rel">
                        <div class="reply-recording text-right ml-5 mr-5" v-if="updateMessage.editMessage == false && getmessageparams.chat_room_id!=''" @click="chooseFiles()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                            </svg>
                        </div>
                        <div class="edit-close text-right" v-if="updateMessage.editMessage == true" @click="cancelEdit()">
                            <i class="fa fa-close"></i>
                        </div>
                        <div v-if="getmessageparams.chat_room_id!=''" class="reply-send">
                            <b-button type="submit" :disabled="(!sendChat.message || (/^\s*$/.test(sendChat.message)) || isContentEmpty(sendChat.message)) && sendChat.mediaData.length == 0" style="padding: 0px 0px;position: relative;left: 0;right: 0;top: 0px!important;bottom: 0;" class="btn chat-reply-btn"><i class="fa fa-send fa-2x" aria-hidden="true"></i></b-button>
                        </div>
                        <emoji-picker v-if="getmessageparams.chat_room_id!=''" @emoji="append" :search="searchEmoji">
                            <button
                                type="button"
                                class="emoji-invoker"
                                slot="emoji-invoker"
                                slot-scope="{ events: { click: clickEvent } }"
                                @click.stop="clickEvent"
                            >
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                                    <path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                                </svg>
                            </button>
                    
                            <div slot="emoji-picker" slot-scope="{ emojis, append, display }">
                                <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                                    <div class="emoji-picker__search">
                                        <input type="text" v-model="searchEmoji">
                                    </div>
                                    <div>
                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                        <h5>{{ category }}</h5>
                                        <div class="emojis">
                                            <span
                                            v-for="(emoji, emojiName) in emojiGroup"
                                            :key="emojiName"
                                            @click="insert(emoji)"
                                            :title="emojiName"
                                            >{{ emoji }}</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </emoji-picker>
                        <div v-if="getmessageparams.chat_room_id!=''" class="settings-btn-wrap">
                            <b-dropdown id="dropdown-dropup" dropup right class="setting-btn enter-btn-setting">
                                <template #button-content>
                                    <i class="fa fa-cog text-black" aria-hidden="true"></i>
                                </template>
                                <b-dropdown-item><b>Pressing Enter Key Will :</b></b-dropdown-item>
                                <b-dropdown-item @click="UpdateEnterBtnRole('A')">
                                    <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" class="custom-control-input" value="A" id="__BVID__62_BV_option_1" name="__BVID__62" v-model="SetEnterBtnRole">
                                        <label class="custom-control-label" for="__BVID__62_BV_option_1"><span>Send Message</span></label>
                                    </div>

                                </b-dropdown-item>
                                <b-dropdown-item @click="UpdateEnterBtnRole('B')">
                                    <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" class="custom-control-input" value="B" id="__BVID__62_BV_option_2" name="__BVID__62" v-model="SetEnterBtnRole">
                                        <label class="custom-control-label" for="__BVID__62_BV_option_2"><span>Add a link break</span></label>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item>You can always use Shift+Enter to type multi-line messages.</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <!-- <textarea class="form-control" v-if="getmessageparams.chat_room_id!=''" v-model="sendChat.message" @keydown="handleEnterKeyEvent($event);sendChat.messageType = 1;" rows="1" id="comment" ></textarea> -->
                        <wysiwyg class="form-control" v-if="getmessageparams.chat_room_id!=''" v-model="sendChat.message" @keyup.enter="sendChat.messageType = 1;"/>
                    </div>
                </form>
            </div>
        </div>
        <!-- this modal is for to image preview -->
        <b-modal id="modal-image-slider" size="xl" title="" centered hide-header hide-footer>
            <b-carousel
                :interval=0
                id="carousel-1"
                v-model="slider"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
                > 
                <!-- Slides with image only -->
                <b-carousel-slide v-for="(data,index) in sliderImages" :key="index"  :img-src="data.media">
                </b-carousel-slide>
                <!-- Slides with img slot -->
            </b-carousel>
            <b-button class="download-btn btn-theme" @click="downloadAllfiles(sliderImages)">Download</b-button>
        </b-modal>
    </span>
</template>
<script>
var that = '';
import $ from 'jquery';
import { mapActions,mapGetters } from "vuex";
export default {
    created() {
        that = this;
        window.addEventListener('keydown', function(event) {
            if(that.$store.state.enterBtnRole == 'A'){
                if (event.keyCode === 13 && !event.shiftKey) { 
                    event.preventDefault();
                    var messageStatus = that.sendChat.message.replaceAll("&nbsp;", "").
                    replaceAll(" ", "").
                    replaceAll("<div><br></div>", "").trim();
                    if(messageStatus != ''){
                        that.submitform();
                    }else{
                        that.sendChat.message = '';
                    }
                }
            }
        });
    },
    props:['chatroomdata','pageType','roomlist','chatroomMembers'],
    data() {
        return {
            count               : 5,
            search              : '',
            searchEmoji         : '',
            messagelist         : [],
            getmessageparams    : {
                chat_room_id        :'',
                start_limit         :0,
                end_limit           :10,
                cycle               :'',
                lastId              :'',
                firstId             :'',
            },
            sendChat            : {
                message             :'',
                messageType         :'',
                mediaData           :[],
            },
            currentUser         : '',
            updateMessage       : {
                chatData            :'',
                chatIndex           :'',
                chatParentIndex     :'',
                chatChildIndex      :'',
                editMessage         :false,
            },
            emoji               : [],
            append              : '',
            preview_list        : [],
            image_list          : [],
            uploadRecentfiles   : {
                chat_room_id        : '',
                message             : '',
                file_path           : '',
                files               : [],
            },
            sliderImages        : '',
            slide               : 0,
            sliding             : null,
            slider              : 0,
            sendMsgStatus       : 0,
            chatLoader          : true,
        }
    },
    methods: {
        ...mapActions(["genericAPIPOSTRequest","cancelApiRequest","UpdateEnterBtnRole"]),
        ...mapActions('global',["UpdateMessageRequestData","EmptyMessageData","UpdateChatRoomResponse","UpdateMessageCycleData"]),
        // this fun is for to download multiple files
        downloadAllfiles(_v){
            for (const data of _v) {
                setTimeout(() => {
                   return that.downloadFile(data.media_withoutbasepath);
                }, 2000);
            }
        },
        // this fun is for to hanle scroll
        handleScroll(event) {
            const currentScrollPosition = event.target.scrollTop;
            if(currentScrollPosition <= 10){
                // that.UpdateMessageCycleData('');
                var messageRequestdata = that.$store.state.global.mRequestData;
                messageRequestdata.cycle = 'up';
                // var element = document.getElementById("conversation");
                // element.classList.add("stop-scrolling");
                // window.addEventListener('DOMMouseScroll',event.preventDefault, false); // older FF
                that.UpdateMessageRequestData({params:messageRequestdata});
                // that.UpdateMessageCycleData('up');
            }
        }, 
        onSlideStart() {
            that.sliding = true
        },
        onSlideEnd() {
            that.sliding = false
        },
        setSlider(data){
            that.sliderImages = data;
        },  
        setImage(index){
            that.slider = index;
        },
        //this fun is for to cancel edit
        cancelEdit() {
            that.updateMessage = {
                chatData            :'',
                chatIndex           :'',
                chatParentIndex     :'',
                chatChildIndex      :'',
                editMessage         :false,
            };
            that.sendChat.message='';
        },
        // this fun is for to submit chat form according to need
        async submitform(){
            if(that.updateMessage.editMessage == true){
                await that.updateMsg();
            }else{
                await that.sendmessageData();
            }
        },
        // this funis for to update chat message
        async updateMsg(){
            var res = await that.genericAPIPOSTRequest({
                requestUrl  :'sendMessage',
                params      : {
                    chat_room_id    : that.updateMessage.chatData.chat_room_id,
                    chat_id         : that.updateMessage.chatData.chat_id,
                    sended_by       : that.$store.state.currentUserData.login_master_id,
                    type            : that.updateMessage.chatData.type,
                    message         : that.sendChat.message,
                },
            });
            if (res) {
                that.messagelist[that.updateMessage.chatIndex].value[that.updateMessage.chatParentIndex].value[that.updateMessage.chatChildIndex].message = that.sendChat.message;
                that.sendChat.message = '';
                that.updateMessage    = {
                    chatData            : '',
                    chatIndex           : '',
                    chatParentIndex     : '',
                    chatChildIndex      : '',
                    editMessage         : false,
                };
            }
        },
        // this fun is for to delete message
        async deleteMsg(_i,index,parentindex,childindex,event){
            var res = await that.genericAPIPOSTRequest({
                requestUrl:'deleteMessage',
                params: {
                    'chat_id'       : _i,
                    'is_deleted'    : 1,
                    'deleted_by'    : that.$store.state.currentUserData.login_master_id,
                },
            });
            if (res ) {
                that.messagelist[index].value[parentindex].value[childindex].message                = '<span class="bg-grey p-2 br-10 text-muted"><i>You removed this message</i></span>';
                that.messagelist[index].value[parentindex].value[childindex].is_deleted             = 1;
                event.target.parentElement.parentElement.parentElement.parentElement.style.display  = 'none';
            }
        },
        // this fun is for to delete message
        async deleteFile(_i,index,parentindex,childindex,childchildindex){
            var res = await that.genericAPIPOSTRequest({
                requestUrl  :'deleteMessage',
                params      : {
                    'chat_id'       : _i,
                    'is_deleted'    : 1,
                    'deleted_by'    : that.$store.state.currentUserData.login_master_id,
                },
            });
            if (res ) {
                that.messagelist[index].value[parentindex].value[childindex].data.splice(childchildindex,1);
            }
        },
        // this function is for to upload attachment
        async UploadFile(e){ 
            that.previewMultiImage(e);
            var error=0;
            for (let index = 0; index < e.target.files.length; index++) {
                var files=e.target.files[index];
                var ext = files.name.split('.').pop();
                if(ext=="pdf" || ext=="word" || ext=="png"|| ext=="jpeg"|| ext=="jpg" || ext=="xlsx" || ext=="xls" || ext=="docx" || ext=="doc"){
                    const fileSize = files.size / 1024 / 1024; // in MiB
                    if ( fileSize <= 25) {
                    // if (fileSize >= 2 && fileSize <= 40) {
                        const formData = new FormData();
                        formData.append('image', files);
                        formData.append('chat_room_id', that.getmessageparams.chat_room_id);
                        formData.append('user_id', that.$store.state.currentUserData.login_master_id);
                        var getData = await that.genericAPIPOSTRequest({
                            requestUrl: "chatFile",
                            params: formData,
                        });
                        if(getData.filename!=""){
                            getData.is_type = ext;
                            that.sendChat.mediaData.push(getData);
                            that.preview_list.filter((data,pindex)=>{
                                if (data.name == files.name) {
                                    data.successStatus = 100;
                                    $('#progressbar_'+pindex).css('width',data.successStatus+'%');
                                    $('#progress-parent_'+pindex).css('display','none');
                                    $('#progress-complete_'+pindex).css('display','block');
                                    return false;
                                }
                            });
                            that.uploadRecentfiles.file_path = getData.uploadPath;
                            that.uploadRecentfiles.chat_room_id = that.getmessageparams.chat_room_id;
                        }else{
                            that.preview_list.splice(index,1);
                            that.image_list.splice(index,1);
                            error++;
                        }
                    }else{
                        that.$toastr.e('File Size Should be Less Than 25 Mb');
                    }
                } else{
                    that.$toastr.e('Only Allow Files (Pdf, Word, Png, Jpeg, Jpg, Xlsx, Xls, Docx, Doc)');
                }
                if(error!=0){
                    that.$toastr.e('Your All file is Not uploaded');
                }
            }
            document.getElementById("fileUpload").value = "";
        },
        // this fun is for to preview the multiple image upload
        previewMultiImage: function(event) {
            var input = event.target;
            var count = input.files.length;
            if (input.files) {
                for (let index = 0; index < count; index++) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        that.image_list.push(e.target.result)
                    }
                    input.files[index].successStatus = Math.floor((Math.random() * 70) + 1);
                    that.preview_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                }
            }
        },
        // this fun is for to remove preview files and images
        removePrev(_i){
            that.preview_list.splice(_i,1);
            that.image_list.splice(_i,1);
            that.sendChat.mediaData.splice(_i,1);
        },
        // this fun is for to choose file
        chooseFiles() {
            document.getElementById("fileUpload").value = "";
            document.getElementById("fileUpload").click()
        },
        // this fun is for to add emoji to message
        insert(emoji) {
            that.sendChat.message += emoji;
        },
        // this fun is for to set get message id
        async getselectedusermessage(){
            that.previousScrollPosition         = 0;
            that.chatLoader                     = true;
            // this.EmptyMessageData();
            that.messagelist                    = [];
            that.getmessageparams.chat_room_id  = that.chatroomdata.chat_room_id;
            that.getmessageparams.start_limit   = 0;
            // that.UpdateMessageRequestData({params:{
            //     chat_room_id        : that.chatroomdata.chat_room_id,
            //     start_limit         : 0,
            //     end_limit           : 10,
            //     cycle               : 'first',
            //     lastId              : '',
            //     firstId             : '',
            // }});
        },
        // this fun is for to get messages
        async readMessages(_v){
            var user_id;
            if(that.$store.state.accountType == 'freelancer'){
                user_id = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.user.user_id){
                user_id = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.company.login_master_id){
                user_id = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.user.user_id){
                user_id = that.$store.state.currentUserData.permission.user_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.currentUserData.permission.user_id == that.$store.state.listAccount.qp_user.user_id){
                user_id = that.$store.state.currentUserData.permission.user_id;
            }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.company.login_master_id){
                user_id = that.$store.state.currentUserData.permission.user_id;
            }
            await that.genericAPIPOSTRequest({
                requestUrl  :'readMessage',
                params      : {
                    luid            : user_id,
                    chat_room_id    : _v,
                    // chat_room_id    : this.getmessageparams.chat_room_id,
                },
            });
            that.$emit('updateUnreadMessage')
        },
        // this fun is for to send messages
        async sendmessageData(){
            var checkcontent        = that.isContentEmpty(that.sendChat.message);
            var checkmsgEmpty       = that.sendChat.message.replaceAll("&nbsp;", "").
            replaceAll("<div><br></div>", "").
            replaceAll( new RegExp( ">[ ]+<" , "g" ) , "><" ).
            replaceAll("<div></div>", "").trim();
            // replaceAll(" ", "").
            // return false;
            var sended_by;
            
            if(that.$store.state.accountType == 'freelancer'){
                sended_by = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.user.user_id){
                sended_by = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.company.login_master_id){
                sended_by = that.$store.state.currentUserData.login_master_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.user.user_id){
                sended_by = that.$store.state.currentUserData.permission.user_id;
            }else if(that.$store.state.accountType == 'client' && that.$store.state.currentUserData.permission.sended_by == that.$store.state.listAccount.qp_user.user_id){
                sended_by = that.$store.state.currentUserData.permission.user_id;
            }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.company.login_master_id){
                sended_by = that.$store.state.currentUserData.permission.user_id;
            }

            if(checkmsgEmpty!='' && checkcontent==false && that.sendMsgStatus == 0){
                that.sendMsgStatus = 1;
                var response = await that.genericAPIPOSTRequest({
                    requestUrl  :'sendMessage',
                    params      : {
                        chat_room_id    : that.getmessageparams.chat_room_id,
                        sended_by       : sended_by,
                        type            : 1,
                        message         : that.sendChat.message.trim(),
                    },
                });
                if (response) {
                    that.uploadRecentfiles.message  = that.sendChat.message;
                    that.sendChat.message           = '';
                    if(that.sendChat.mediaData.length > 0){
                        that.sendChat.mediaData.map((data,pindex)=>{
                            that.uploadRecentfiles.files.push({'type':data.is_type,'name':data.name,'size':data.size});
                            if(data.is_type =="png"|| data.is_type =="jpeg"|| data.is_type =="jpg") {
                                data.is_type = 1;
                            }else if(data.is_type =="pdf" || data.is_type =="word" ){
                                data.is_type = 3;
                            }
                            var res = that.genericAPIPOSTRequest({
                                requestUrl  :'sendMessage',
                                params      : {
                                    chat_room_id        : that.getmessageparams.chat_room_id,
                                    sended_by           : sended_by,
                                    type                : (data.is_type == 1)?'2':'3',
                                    size                : data.size,
                                    message             : data.name,
                                    file_type           : that.preview_list[pindex].type,
                                    parent_id           : response,
                                },
                            });
                            if (res) {
                                that.$emit('refreshFiles')
                                // var elements = document.getElementById("conversation");
                                // setTimeout(() => {
                                    // elements.scrollTo({top: elements.scrollHeight+10000, behavior: 'smooth'});
                                    // elements.scrollBy(0,1000);
                                // }, 2000);
                            }
                        });
                        if(that.uploadRecentfiles.files.length > 0){
                            that.addRecentfiles();
                        }
                        that.preview_list           = [];
                        that.image_list             = [];
                        that.sendChat.mediaData     = [];
                    }else{
                        // var element = document.getElementById("conversation");
                        // setTimeout(() => {
                            // element.scrollTo({top: element.scrollHeight+10000, behavior: 'smooth'});
                            // element.scrollBy(0,1000);
                        // }, 2000);
                    }
                }
                that.sendMsgStatus = 0;
                that.UpdateMessageCycleData('');
            }else if(that.sendChat.mediaData.length > 0 && that.sendMsgStatus == 0){
                that.sendMsgStatus = 1;
                that.sendChat.mediaData.map((data,pindex)=>{
                    that.uploadRecentfiles.files.push({'type':data.is_type,'name':data.name,'size':data.size});
                    if(data.is_type =="png"|| data.is_type =="jpeg"|| data.is_type =="jpg") {
                        data.is_type = 1;
                    }else if(data.is_type =="pdf" || data.is_type =="word" ){
                        data.is_type = 3;
                    }
                    var res1 = that.genericAPIPOSTRequest({
                        requestUrl  :'sendMessage',
                        params      : {
                            chat_room_id    : that.getmessageparams.chat_room_id,
                            sended_by       : sended_by,
                            type            : (data.is_type == 1)?'2':'3',
                            size            : data.size,
                            message         : data.name,
                            file_type       : that.preview_list[pindex].type,
                        },
                    });
                    if (res1) {
                        that.$emit('refreshFiles')
                        // var element1 = document.getElementById("conversation");
                        // setTimeout(() => {
                            // element1.scrollTo({top: element1.scrollHeight+10000, behavior: 'smooth'});
                            // element1.scrollBy(0,1000);

                        // }, 2000);
                    }
                });
                that.sendMsgStatus = 0;
                if(that.uploadRecentfiles.files.length > 0){
                    that.addRecentfiles();
                }
                that.preview_list           = [];
                that.image_list             = [];
                that.sendChat.mediaData     = [];
                that.UpdateMessageCycleData('');

            }
        },
        // this fun is for to add recent files to database
        async addRecentfiles(){
            var res = await that.genericAPIPOSTRequest({
                requestUrl  : "addContractfiles",
                params      : {
                    files           : that.uploadRecentfiles.files,
                    file_path       : that.uploadRecentfiles.file_path,
                    message         : that.uploadRecentfiles.message,
                    chat_room_id    : that.uploadRecentfiles.chat_room_id,
                    type            :'Chat-Files'
                },
            });
            if(res){
                that.uploadRecentfiles  = {
                    job_application_id      :'',
                    message                 :'',
                    file_path               :'',
                    files                   :[],
                };
            }
        },
        // function to detect if the content is empty
        isContentEmpty(text) {
            text = text.replaceAll("&nbsp;", "").
            // replaceAll(" ", "").
            replaceAll("<div><br></div>", "").
            replaceAll( new RegExp( ">[ ]+<" , "g" ) , "><" ).
            replaceAll("<div></div>", "").trim();
            return (text.length == 0) ? true : false;
        },
        // function to refresh chat messages
        async refreshChatMessage(){
            that.previousScrollPosition         = 0;
            that.messagelist                    = [];
            that.getmessageparams.chat_room_id  = '';
            that.getmessageparams.start_limit   = 0;
            that.EmptyMessageData({params : []});
            await that.UpdateMessageRequestData({params:{
                chat_room_id        : '',
                start_limit         : 0,
                end_limit           : 10,
                cycle               : 'first',
                lastId              : '',
                firstId             : '',
            }});
            // that.getmessageDataLoad(that.messageCycle);
        },
    },
    watch: {
        // this fun is for to check user is online or offline
        roomlist(){
            var res = that.roomlist.filter(data=>{
                return data.chat_room_id === that.chatroomdata.chat_room_id;
            });
            if(res.length > 0){
                that.readMessages(that.chatroomdata.chat_room_id);
                res[0].onlineData.map(data=>{
                    var elements =  document.getElementsByClassName('status-'+data.login_master_id)
                    const response = that.checkUserOnlineOrOffline(data);
                    if(response == true){
                        for (var i = 0; i < elements.length; i++) {
                            elements[i].classList.add('bg-success');
                        }
                    }else{
                        for (var ii = 0; ii < elements.length; ii++) {
                            elements[ii].classList.remove('bg-success');
                        }
                    }
                });
            }
        },
        // this fun is for to watch chat data
        getmChatData(){
            // console.log('that.getmChatData',that.getmessageCycle);
            if(that.getmChatData.length > 0){
                that.messagelist = that.getmChatData;
                if(that.getmessageCycle == 'first'){
                    that.readMessages(that.chatroomdata.chat_room_id);
                }
            }else{
                that.messagelist = [];
            }
        },
        // this fun is for to watch message cycle
        getmessageCycle(){
            // console.log('cycle',that.getmessageCycle);
            var element = document.getElementById("conversation");
            if(that.getmessageCycle == 'first'){
                setTimeout(() => {
                    element.scrollTo({top: element.scrollHeight+10000, behavior: 'smooth'});
                    setTimeout(() => {
                        that.UpdateChatRoomResponse(true);
                        that.chatLoader = false;
                    }, 1000);
                    that.previousScrollPosition = element.scrollHeight;
                }, 1000);
            }else if(that.getmessageCycle == 'recurring'){
                setTimeout(() => {
                    element.scrollTo({top: element.scrollHeight+10000, behavior: 'smooth'});
                    that.previousScrollPosition = element.scrollHeight;
                    // that.readMessages(that.chatroomdata.chat_room_id);
                }, 1000);
            }else if(that.getmessageCycle == 'up'){
                // console.log('up-cycle',that.getmessageCycle)
                setTimeout(() => {
                    element.scrollTo({top: element.scrollHeight - that.previousScrollPosition, behavior: 'smooth'});
                    that.previousScrollPosition = element.scrollHeight - that.previousScrollPosition;
                }, 1000);
            }
        },
        // messagelist(){
        //     console.log('this.messagelist',that.getmessageCycle)
        // }
    },
    component:{
        // 'vue-editor': VueEditor,
        // VueEditor
        // VueReadMoreSmooth
        // saveAs
    },
    mounted() {
        window.scrollTo(0, 0);
        that = this;
    },
    computed:{
        ...mapGetters('global',["getmChatData","getmessageCycle","getchatroomResponse"]),
        ...mapGetters({loaderHideShow : "global/getchatLoader",SetEnterBtnRole: "getenterBtnRole"}),
    },
    filters:{
        sizeInMb(value){
            let  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (value == 0) return '0 Byte';
            let i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
            return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }
    },
}
</script>
<style>
    .b-sidebar > .b-sidebar-body{
        overflow-y:hidden!important;
    }
    .b-sidebar > .b-sidebar-body{
        overflow-y:auto!important;
    }
    .chat-wrap .message-head{
        overflow:hidden;
    }
    .chat-wrap .conversation{
        border-right:0px;
    }
    .chat-wrap .conversation{
        height:auto;
    }
    .chat-wrap .message-head{
        height: 100%;
    }
    #conversation{
        overflow-y:hidden!important;
        height:100%;
    }
    #conversation:hover{
        overflow-y:auto!important;
    }
    #conversation{
       /* height:390px;*/
       height:100%;
    }
     
</style>